import React, { useState, useEffect } from "react"
import debounce from "debounce"
import "./progress-bar.scss"

function ReadingProgressBar({ scrollWrapper }) {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    if (!scrollWrapper) return
    const debouncedOnScroll = debounce(
      (e) => {
        if (!e.target) return
        if (!e.target.children.length) return
        const scrollPos = e.target.scrollTop
        const scrollEl = e.target.children[0]
        const max = scrollEl.scrollHeight - window.innerHeight
        const newPercent = Math.round((scrollPos / max) * 1000) / 10
        setPercent(newPercent)
      },
      5,
      true
    )
    scrollWrapper.addEventListener("scroll", debouncedOnScroll)
    return () => scrollWrapper.removeEventListener("scroll", debouncedOnScroll)
  }, [scrollWrapper])
  return (
    <div style={{ width: `${percent}%` }} className="reading-progress-bar" />
  )
}

export default ReadingProgressBar
