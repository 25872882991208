import { useCallback, useMemo, useEffect } from "react"
import { navigate } from "gatsby"

const STORAGE_LANG_KEY = "lang"

export const ALL_LANGS = [
  { id: "de", name: "Deutsch", short: "Deu", locale: "de-DE" },
  { id: "en", name: "English", short: "Eng", locale: "en-US" },
  { id: "ru", name: "Русский", short: "Рус", locale: "ru-RU" },
  { id: "uk", name: "Українська", short: "Укр", locale: "uk-UA" },
]

export function useLanguages(pageLangId, allArticles, firstLoad) {
  const allLanguages = useMemo(() => {
    const distinctLangIds = allArticles.reduce(
      (acc, a) => (a && !acc.includes(a.langId) ? [...acc, a.langId] : acc),
      []
    )
    return distinctLangIds
      .map((id) => ALL_LANGS.find((l) => l.id === id))
      .filter((l) => l)
      .sort((a, b) => (a.name < b.name ? -1 : 1))
  }, [allArticles])

  const lang = useMemo(
    () => allLanguages.find((l) => l.id === pageLangId) || allLanguages[0],
    [allLanguages, pageLangId]
  )

  useEffect(() => {
    if (firstLoad && !pageLangId) {
      const newLang = getLangOnLoad(allLanguages)
      // console.log(newLang, allLanguages);
      navigate(newLang.id)
    }
  }, [firstLoad, lang.id, pageLangId, allLanguages])

  useEffect(() => {
    if (typeof window === "undefined") return
    window.localStorage.setItem(STORAGE_LANG_KEY, lang.id)
  }, [lang])

  return [lang, allLanguages]
}

export function useLangSetter(selected) {
  const setLangById = useCallback(
    (id) => {
      const dest = selected.title
        ? `/${selected.translations.find((a) => a.langId === id).slug}`
        : `/${id}`
      navigate(dest)
    },
    [selected]
  )
  return setLangById
}

function getLangOnLoad(languages) {
  const getCompatibleLang = (langId) => languages.find((l) => l.id === langId)
  return (
    (typeof window === "object"
      ? getCompatibleLang(localStorage.getItem(STORAGE_LANG_KEY))
      : false) ||
    getCompatibleLang(navLang()) ||
    languages[0]
  )
}

function navLang() {
  if (typeof navigator === "undefined") return
  return (navigator.language || navigator.userLanguage).split("-")[0]
}
