import { useState, useEffect } from "react"
import scrollIntoView from "scroll-into-view"

export default function useAnchorChecker(selected) {
  const [anchor, setAnchor] = useState(getAnchor())
  useEffect(() => {
    if (!selected || !anchor) return
    const anchorEl = document.getElementById(anchor)
    if (!anchorEl) return
    setTimeout(() => {
      scrollIntoView(anchorEl, { align: { top: 0, topOffset: 0 } })
    }, 1000)
    setAnchor("")
  }, [selected, anchor])
}

function getAnchor() {
  if (typeof window !== "undefined") {
    const urlSplit = window.location.href.split("#")
    if (urlSplit.length > 1) return urlSplit[1]
  }
}
