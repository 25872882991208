import React, { useContext } from "react"
import "./language-menu.scss"
import { LangContext } from "gatsby-source-dek-wp"

function LanguageMenu({ closeAllMenus, open, setLang, languages }) {
  const lang = useContext(LangContext)

  function handleLanguageChange(langId) {
    closeAllMenus()
    // setLanguageMenuOpen(false)
    setLang(langId)
  }

  return (
    <ul className={`language-menu ${open ? " open" : " closed"}`}>
      {languages.map((l) => {
        // const dest =
        return (
          <li key={"lang_li_" + l.id}>
            <button
              className={
                l.id === lang.id
                  ? "language-option selected"
                  : "language-option"
              }
              value={l.id}
              onClick={() => handleLanguageChange(l.id)}
            >
              {l.name}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default LanguageMenu
