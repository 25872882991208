import React, { useState, useRef, useMemo, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./background-video.scss"

export default function useVideo(initiallyPlaying, articles, backgroundVideos) {
  const bgVideosFromArticles = useMemo(() => {
    // console.log(articles);
    return articles.reduce((acc, article) => {
      return article.titleVideo &&
        // article.titleVideoPoster &&
        !acc.find((v) => v.videoUrl === article.titleVideo)
        ? [
            ...acc,
            {
              videoUrl: article.titleVideo,
              posterUrl: article.titleVideoPoster,
              hours: [],
            },
          ]
        : acc
    }, [])
  }, [articles])

  const bgVideos = backgroundVideos || bgVideosFromArticles
  const [videoProps, setVideoProps] = useState({
    playing: initiallyPlaying,
    index: 0,
  })
  const videoComp = (
    <BackgroundVideo
      videos={bgVideos}
      videoProps={videoProps}
      setVideoProps={setVideoProps}
    />
  )
  return [setVideoProps, videoComp]
}

function BackgroundVideo({ videos = [], videoProps, setVideoProps }) {
  const { playing, index } = videoProps
  const videoRef = useRef()
  const [muted, setMuted] = useState(true)
  const video = useMemo(() => videos[index] || {}, [videos, index])

  useEffect(() => {
    if (!video.posterUrl) return
    document.body.style.backgroundImage = `url("${video.posterUrl}")`
  }, [video.posterUrl])

  useEffect(() => {
    if (!videoRef.current) return
    videoRef.current.load()
  }, [video.videoUrl])

  useEffect(() => {
    if (!videoRef.current) return
    if (playing) {
      videoRef.current.play().catch(console.error)
      /* if (playPromise !== null) {
        playPromise.catch(videoRef.current.play)
      } */
    } else {
      videoRef.current.pause()
    }
  }, [playing, videoRef])

  useEffect(() => {
    function getTimebasedVideoIndex() {
      const hour = new Date().getHours()
      const matchingVideo = videos.find((video) => video.hours.includes(hour))
      return matchingVideo ? videos.indexOf(matchingVideo) : 0 // fallback default
    }
    const setVideoIndex = (index) => setVideoProps((p) => ({ ...p, index }))
    setVideoIndex(getTimebasedVideoIndex())
  }, [videos, setVideoProps])

  const setPlaying = (playing) => setVideoProps((p) => ({ ...p, playing }))

  return (
    <div
      className={
        "background-video-container " + (playing ? "playing" : "not-playing")
      }
    >
      <video // eslint-disable-line jsx-a11y/media-has-caption
        ref={videoRef}
        id="background-video"
        muted={muted}
        controls={false}
        autoPlay={playing}
        playsInline
        loop
        poster={video.posterUrl}
      >
        {video.videoUrl && (
          <source src={video.videoUrl} type="video/mp4"></source>
        )}
      </video>
      <div className="background-video-overlay"></div>
      <button
        className={
          "background-video-volume-container " +
          (playing ? "playing" : "not-playing")
        }
        onClick={() => setMuted((m) => !m)}
      >
        <FontAwesomeIcon icon={muted ? "volume-mute" : "volume-up"} />
      </button>
      <button
        className="background-video-control-container"
        onClick={() => setPlaying(!playing)}
      >
        <FontAwesomeIcon icon={playing ? "pause" : "play"} />
      </button>
    </div>
  )
}
