import React, { useState, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./newsletter-form.scss"
import { LangContext } from "../../helpers"

const subscribe = {
  de: "Eintragen",
  en: "Subscribe",
  ru: "Отправить",
  uk: "Надіслати",
}

const placeholder = {
  de: "Ihre E-Mail-Adresse",
  en: "Your e-mail address",
  ru: "Ваш адрес электронной почты",
  uk: "Ваша адреса електронної пошти",
}

function NewsletterForm() {
  const langId = useContext(LangContext)
  const [gdprText, setGdprText] = useState("")
  function magic() {
    if (typeof window !== "undefined") window.localStorage.setItem("dev", true)
    alert("magic!")
    window.location.reload()
  }
  function unmagic() {
    if (typeof window !== "undefined") window.localStorage.removeItem("dev")
    alert("no more magic!")
    window.location.reload()
  }
  function showGdprText(e) {
    if (langId !== "de")
      setGdprText(
        'You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our <a href="https://www.dekoder.org/de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">website</a>.<br/><br/>We use Mailchimp as our marketing platform. By clicking above to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank" rel="noopener noreferrer">Learn more about Mailchimp\'s privacy practices here.'
      )
    if (hash(e.target.value) === 1754729689) magic()
    else if (hash(e.target.value) === -283389260) unmagic()
  }

  const langNum = {
    en: 1,
    ru: 2,
    ua: 4,
  }
  const urlInt =
    "https://dekoder.us11.list-manage.com/subscribe/post?u=9acef6a8d27296b32506a9b10&amp;id=eef25a3254"
  const urlDe =
    "https://dekoder.us11.list-manage.com/subscribe/post?u=9acef6a8d27296b32506a9b10&amp;id=bf6fe63850"
  return (
    <React.Fragment>
      <div className="newsletter-form-container">
        <div className="mc_embed_signup">
          <form
            action={langId === "de" ? urlDe : urlInt}
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="ph-newsletter-form"
            target="_blank"
            noValidate=""
          >
            <div className="mc_embed_signup_scroll">
              <label className="ph-newsletter-form-label" htmlFor="mce-EMAIL">
                Newsletter
              </label>
              <input
                className="ph-newsletter-form-input"
                type="email"
                name="EMAIL"
                placeholder={placeholder[langId]}
                onChange={(e) => showGdprText(e)}
              />
              <button
                type="submit"
                name="subscribe"
                className="ph-newsletter-form-btn"
              >
                <FontAwesomeIcon icon="envelope" /> {subscribe[langId]}
              </button>
              {langId === "de" ? (
                ""
              ) : (
                <React.Fragment>
                  <input
                    type="hidden"
                    name="group[16425]"
                    value={langNum[langId]}
                  />
                  <input type="hidden" name="gdpr[85]" value="Y" />
                </React.Fragment>
              )}
            </div>
          </form>
          <div
            className="newsletter-gdpr-text"
            dangerouslySetInnerHTML={{ __html: gdprText }}
          />
        </div>
      </div>
      <figure className="wp-block-image">
        <img
          src="https://specials.dekoder.org/content/uploads/2019/03/kommersant-postbox.jpg"
          alt="newsletter"
        />
        <figcaption>Foto © Pjotr Kassin/Kommersant</figcaption>
      </figure>
    </React.Fragment>
  )
}

export default NewsletterForm

function hash(str) {
  var hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}
