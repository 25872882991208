import React, { useContext } from "react"
import transformer from "../../modules/transformer"
import { LangContext } from "gatsby-source-dek-wp"
import "./article-meta.scss"

const authorPrefix = { de: "Text", en: "Text", ru: "Текст", uk: "Текст" }
const videoSourcePrefix = {
  de: "Video",
  en: "Video",
  ru: "Видео",
  uk: "Відео",
}
const videoCutPrefix = {
  de: "Schnitt",
  en: "Video Editing",
  ru: "Монтаж",
  uk: "Монтаж",
}
const photoEditorPrefix = {
  de: "Bildredaktion",
  en: "Photo Editing",
  ru: "Photo Editing",
  uk: "Photo Editing",
}
const translatorPrefix = {
  de: "Übersetzung",
  en: "Translation",
  ru: "Перевод",
  uk: "Переклад",
}
const dataVisualizationPrefix = {
  de: "Datenvisualisierung",
  en: "Data visualisation",
  ru: "Визуализация данных",
  uk: "візуалізація даних",
}

export default function ArticleMeta(props) {
  const {
    author,
    photoEditor,
    translator,
    date,
    titleVideoSource,
    titleVideoCut,
    dataVisualization,
  } = props
  const lang = useContext(LangContext)
  return (
    <div className="article-meta">
      {!!author && (
        <span className="meta-item">
          {authorPrefix[lang.id]}: {transformer(author)}
        </span>
      )}
      {!!photoEditor && (
        <span className="meta-item">
          {photoEditorPrefix[lang.id]}: {transformer(photoEditor)}
        </span>
      )}
      {!!translator && (
        <span className="meta-item">
          {translatorPrefix[lang.id]}: {transformer(translator)}
        </span>
      )}
      {!!titleVideoSource && (
        <span className="meta-item">
          {videoSourcePrefix[lang.id]}: {transformer(titleVideoSource)}
        </span>
      )}
      {!!titleVideoCut && (
        <span className="meta-item">
          {videoCutPrefix[lang.id]}: {transformer(titleVideoCut)}
        </span>
      )}
      {!!dataVisualization && (
        <span className="meta-item">
          {dataVisualizationPrefix[lang.id]}: {transformer(dataVisualization)}
        </span>
      )}
      {!!date && <span className="meta-item">{getFormatedDate(date)}</span>}
    </div>
  )
}

function getFormatedDate(dateString) {
  const date = new Date(dateString)
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  let yyyy = date.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }
  return dd + "." + mm + "." + yyyy
}
