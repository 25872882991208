import React, { useEffect } from "react"
import { LangContext, ALL_LANGS } from "gatsby-source-dek-wp"

const LayoutStandalone = ({ pageContext, children, metaData }) => {
  const lang =
    ALL_LANGS.find((l) => l.id === pageContext.langId) || ALL_LANGS[0]
  useEffect(() => {
    document.body.classList.add("standalone-layout")
  }, [])
  return (
    <LangContext.Provider value={lang}>
      <div className="standalone-layout-container">{children}</div>
    </LangContext.Provider>
  )
}

export default LayoutStandalone
