import "./barInfographic.scss"
// import Highcharts from 'highcharts';

function isMobile() {
  return window.innerWidth <= 500
}

function getJsDateFromExcel(excelDate) {
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000)
}

function isDate(string) {
  return !isNaN(new Date(string).getYear())
}

function prepareData(data) {
  const newData = []
  data.forEach((entry) => {
    const newEntry = {}
    newEntry.values = []
    newEntry.name = {}
    newEntry.label = {}
    for (let key in entry) {
      if (key === "name") newEntry.name.de = entry[key]
      else if (key.match(/name_[a-z]+/)) {
        const lng = key.split("_")[1]
        newEntry.name[lng] = entry[key]
      } else if (key === "label") newEntry.label.de = entry[key]
      else if (key.match(/name_[a-z]+/)) {
        const lng = key.split("_")[1]
        newEntry.label[lng] = entry[key]
      } else if (key === "marked") newEntry.marked = entry[key]
      else if (key === "fixedLast") newEntry.fixedLast = entry[key]
      else {
        let x
        if (parseInt(key).toString().length === 5) x = getJsDateFromExcel(key)
        else if (isDate(key)) x = new Date(key)
        else if (typeof key === "string") x = key
        else return
        newEntry.values.push({
          x: isDate(x) ? x.getFullYear() : x,
          date: isDate(x) ? x : null,
          value: entry[key],
        })
      }
    }
    newData.push(newEntry)
  })

  return newData
}

function createHighchartsChart(containerEl, valueArray, suffix) {
  if (typeof Highcharts === "undefined") return
  /* return new Highcharts.Chart({
    chart: {
      renderTo: containerEl,
      height: 150,
      backgroundColor: 'transparent',
      width: isMobile() ? window.innerWidth - 20 : 298,
      style: {fontFamily: 'Roboto Condensed'}
    },
    title: {text: null},
    exporting: {enabled: false},
    xAxis: {
      type: 'datetime',
      tickInterval: 157680000000
    },
    yAxis: {
      labels: {format: suffix ? '{value}' + suffix : '{value}'},
      title: {text: null}
    },
    legend: {enabled: false},
    series: [{
      data: valueArray,
      color: '#129fc9'
    }],
    credits: {enabled: false}
  });*/
}

function newBarEl(obj) {
  let index = obj.index || 0
  const result = document.createElement("div")
  result.classList.add("infographic-bar")
  if (obj.marked) result.classList.add("marked")
  result.classList.add(obj.infographic.options.split ? "split" : "no-split")
  result.style.backgroundColor = obj.infographic.options.colors[index]
  const parentEl = obj.barContainerEl
    ? obj.barContainerEl
    : obj.field.barContainerEl
  parentEl.appendChild(result)
  return result
}

function newValueEl(series) {
  const result = document.createElement("div")
  result.classList.add("infographic-field-value")
  if (series.infographic.options.valueLabelsStickLeft)
    result.classList.add("stick-left")
  series.barEl.appendChild(result)
  // series.domEl.appendChild(result);
  return result
}

class InfographicOptions {
  constructor(customOptions, infographic) {
    // default options
    this.infographic = infographic
    this.lang = "de"
    this.percent = false
    this.suffix = " %"
    this.noOrder = false
    this.horiziontal = false
    this.columnHeight = 400
    this.overallMax = false
    this.split = false
    this.barHeight = 36
    this.barSpacing = 5
    this.barOffset = 0
    this.valueLabelsStickLeft = false
    this.colors = [
      "rgba(18,159,201, 0.5)",
      "rgba(201,51,36, 0.5)",
      "rgba(18,159,201, 0.5)",
      "rgba(201,51,36, 0.5)",
      "rgba(18,159,201, 0.5)",
      "rgba(201,51,36, 0.5)",
      "rgba(18,159,201, 0.5)",
      "rgba(201,51,36, 0.5)",
    ] // ['#129fc9', '#c93324']
    // merge in custom uptions
    for (let key in customOptions) {
      this[key] = customOptions[key]
    }
  }
  get lang() {
    return this._lang
  }
  get localeStr() {
    const mappings = {
      de: "de-DE",
      en: "en-US",
      ru: "ru-RU",
      ua: "ua-UA",
    }
    return mappings[this.lang]
  }
  get percent() {
    return this._percent
  }
  get suffix() {
    return this._suffix
  }
  get noOrder() {
    return this._noOrder
  }
  get horizontal() {
    return this._horizontal
  }
  get columnHeight() {
    return this._columnHeight
  }
  get overallMax() {
    return this._overallMax
  }
  get split() {
    return this._split
  }
  get barHeight() {
    return this._barHeight
  }
  get barSpacing() {
    return this._barSpacing
  }
  get barOffset() {
    return this._barOffset
  }
  get valueLabelsStickLeft() {
    return this.barOffset > 0
  } // return this._valueLabelsStickLeft;}
  get colors() {
    return this._colors
  }

  set lang(val) {
    this._lang = val
    this.updateFields()
  }
  set percent(val) {
    this._percent = val
    this.updateFields()
  }
  set suffix(val) {
    this._suffix = val
    this.updateFields()
  }
  set noOrder(val) {
    this._noOrder = val
    this.updateFields()
  }
  set horizontal(val) {
    this._horizontal = val
    this.infographic.domEl.classList.add(val ? "horizontal" : "vertical")
    this.infographic.domEl.classList.remove(val ? "vertical" : "horizontal")
  }
  set columnHeight(val) {
    this._columnHeight = val
    this.updateHeight()
    this.updateFields()
  }
  set overallMax(val) {
    this._overallMax = val
    this.updateFields()
  }
  set split(val) {
    this._split = val
    this.infographic.domEl.classList.add(val ? "split" : "no-split")
    this.infographic.domEl.classList.remove(val ? "no-split" : "split")
  }
  set barHeight(val) {
    this._barHeight = val
  }
  set barSpacing(val) {
    this._barSpacing = val
    this.updateHeight()
    this.updateFields()
  }
  set barOffset(val) {
    this._barOffset = val
    if (val > 0) {
      this.valueLabelsStickLeft = true
      this.infographic.domEl.classList.add("bar-offset")
    } else {
      this.valueLabelsStickLeft = false
      this.infographic.domEl.classList.remove("bar-offset")
    }
    this.updateFields()
  }
  set valueLabelsStickLeft(val) {
    this._valueLabelsStickLeft = val
  }
  set colors(val) {
    this._colors = val
  }

  updateFields() {
    if (this.infographic._fields) this.infographic.updateFields()
  }
  updateHeight() {
    this.infographic.setHeight()
  }
}

class BarFieldValue {
  constructor(object, field, index) {
    this.x = object.x
    this._value = object.value
    this.date = object.date || null
    this.field = field
    this.infographic = field.infographic
    this.index = index
    this.barEl = field.infographic.options.split
      ? newBarEl(this)
      : this.field.sharedBarEl
    this.valueEl = field.infographic.options.split
      ? newValueEl(this)
      : this.field.sharedValueEl

    if (!this.infographic.series[this.x]) {
      this.infographic.series[this.x] = []
    }
    this.infographic.series[this.x].push(this)
  }

  // getters & setters
  get value() {
    return this._value
  }
  get percent() {
    return Math.round((this.value / this.sumOfAllValuesPerYear) * 1000) / 10
  }
  get compareableValue() {
    if (this.infographic.options.percent)
      return isNaN(this.percent) ? -1 : this.percent
    else return isNaN(this.value) ? -1 : this.value
  }
  get printValue() {
    if (this.infographic.options.percent) {
      return (
        this.percent.toLocaleString(this.infographic.options.localeStr) + " %"
      )
    } else
      return this.infographic.options.suffix
        ? this.value.toLocaleString(this.infographic.options.localeStr) +
            this.infographic.options.suffix
        : this.value.toLocaleString(this.infographic.options.localeStr)
  }
  get sumOfAllValuesPerYear() {
    return this.infographic.series[this.x]
      .map((field) => field.value)
      .reduce((acc, val) => acc + val)
  }
  get relSize() {
    const maxValue = this.infographic.options.overallMax
      ? this.infographic.overallMaxValue
      : this.infographic.xMaxValue
    if (this.infographic.options.percent)
      return Math.round((this.percent / maxValue) * 100)
    else return Math.round((this.compareableValue / maxValue) * 100)
  }
  get absoluteBarWidth() {
    return Math.round((this.relSize * this.infographic.domEl.offsetWidth) / 100)
  }

  // methods
  updateLabel() {
    let self = this
    this.valueEl.innerHTML = self.printValue
    // if (isLabelBroaderThanBar()) this.valueEl.style.left = (self.field.labelWidth + 15) + 'px';
    const barContainerWidth = this.field.domEl.offsetWidth
    /*this.valueEl.style.left = (barContainerWidth - 60) + 'px';*/
    this.valueEl.style.width = "60px"
    this.field.labelEl.style.maxWidth = barContainerWidth - 70 + "px"
    /*function isLabelBroaderThanBar() {
      return self.field.labelWidth + self.valueEl.offsetWidth + 20 > self.absoluteBarWidth;
    }*/
  }
  update() {
    this.field.domEl.style.top =
      this.field.rankingPos *
        (this.field.domEl.offsetHeight + this.infographic.options.barSpacing) +
      "px"
    this.field.domEl.style.display = isNaN(this.value) ? "none" : "block"
    this.barEl.style.width = this.relSize + "%"
    //  .attr('aria-label', this.label + ': ' + this.value + this.infographic.options.suffix);
    this.updateLabel()
    // setTimeout(self.updateLabel.bind(self), 500);
  }
}

class BarField {
  constructor(fieldData, infographic) {
    let self = this
    this.infographic = infographic
    this._name = fieldData.name
    this._label = fieldData.label
    this.marked = fieldData.marked || false
    this.fixedLast = fieldData.fixedLast || false // has no functionality yet
    this.domEl = document.createElement("div")
    this.domEl.classList.add("infographic-field")
    this.infographic.domEl.appendChild(this.domEl)
    this.domEl.addEventListener("mousemove", self.showTooltip.bind(self))
    this.domEl.addEventListener("mouseout", self.hideTooltip.bind(self))
    // .on('click', self.click.bind(self))

    this.labelEl = document.createElement("div")
    this.labelEl.classList.add("infographic-field-label")
    this.labelEl.innerHTML = this.name
    this.domEl.appendChild(this.labelEl)

    this.barContainerEl = document.createElement("div")
    this.barContainerEl.classList.add("infographic-bar-container")
    this.domEl.appendChild(this.barContainerEl)

    this.sharedBarEl = this.infographic.options.split ? false : newBarEl(this)
    this.sharedValueEl = document.createElement("div")
    this.sharedValueEl.classList.add("infographic-field-value")
    if (!this.infographic.options.split)
      this.domEl.appendChild(this.sharedValueEl) // this.sharedBarEl.appendChild(this.sharedValueEl);

    this.values = []
    fieldData.values.forEach((obj) => {
      self.values.push(new BarFieldValue(obj, self, self.values.length))
    })
    this.chartEl =
      this.infographic.multipleDatasets && fieldData.values[0].date
        ? this.newHighchartsChart()
        : null
  }

  // getters & setters
  get name() {
    return this._name[this.infographic.options.lang]
  }
  get label() {
    return (
      this._label[this.infographic.options.lang] ||
      this._name[this.infographic.options.lang]
    )
  }
  get labelWidth() {
    return this.labelEl.offsetWidth
  }
  get selectedSeries() {
    let self = this
    return this.values.filter((value) => value.x === self.infographic.x)[0]
  }
  get overallMaxValue() {
    let result = this.values
      .map((value) =>
        this.infographic.options.percent ? value.percent : parseInt(value.value)
      )
      .reduce((a, b) => Math.max(a, b))
    return result
  }
  get barEl() {
    return this.sharedBarEl
  }
  get rankingPos() {
    return this.infographic.fields.indexOf(this)
  }

  // methods
  showTooltip(e) {
    const self = this
    this.infographic.tooltipEl.classList.add(
      isClickInUpperHalf(e) ? "bottom" : "top"
    )
    this.infographic.tooltipEl.classList.remove(
      isClickInUpperHalf(e) ? "top" : "bottom"
    )
    this.infographic.tooltipEl.innerHTML = tooltipHtml()
    // if (this.chartEl) this.infographic.tooltipEl.appendChild(this.chartEl);

    const offset = calcTooltipOffset(e)
    this.infographic.tooltipEl.style.left = offset.x + "px"
    this.infographic.tooltipEl.style.top = offset.y + "px"
    this.infographic.tooltipEl.style.display = "block"

    function tooltipHtml() {
      let result
      if (self.infographic.options.split) {
        result =
          '<div class="tooltip-header"><strong>' +
          self.label +
          "</strong></div>" +
          self.values
            .map(
              (value) =>
                '<div class="infographic-tooltip-row" style="background-color: ' +
                value.infographic.options.colors[value.index] +
                "; width: " +
                value.relSize +
                '%;">' +
                value.x +
                ': <span style="right: 5px; position: absolute"><strong>' +
                value.printValue +
                "</strong></span></div>"
            )
            .join("")
      } else
        result =
          '<div class="tooltip-header">' +
          self.label +
          ": <strong>" +
          self.selectedSeries.printValue +
          "</strong></div>"
      if (self.chartEl) result += self.chartEl.innerHTML
      return result
    }

    function calcTooltipOffset(e) {
      if (isMobile()) return { x: 0, y: 0 }
      else {
        const CURSOR_DISTANCE = 15
        const elWidth = self.infographic.tooltipEl.offsetWidth + CURSOR_DISTANCE
        const elHeight =
          self.infographic.tooltipEl.offsetHeight + CURSOR_DISTANCE
        return {
          x:
            e.screenX + elWidth > window.innerWidth
              ? e.pageX - elWidth
              : e.pageX + CURSOR_DISTANCE,
          y:
            e.screenY + elHeight > window.innerHeight
              ? e.pageY - elHeight
              : e.pageY + CURSOR_DISTANCE,
        }
      }
    }
    function isClickInUpperHalf(e) {
      return e.clientY < window.innerHeight / 2
    }
  }
  hideTooltip() {
    this.infographic.tooltipEl.innerHTML = ""
    this.infographic.tooltipEl.style.display = "none"
  }
  click() {
    this.marked = !this.marked
    this.barEl.classList.toggle("marked")
  }
  update() {
    this.labelEl.innerHTML = this.name
    this.barContainerEl.style.left = this.infographic.options.barOffset + "px"
    this.barContainerEl.style.width = this.infographic.options.barOffset
      ? "calc(100% - " + this.infographic.options.barOffset + "px)"
      : "100%"
    this.barContainerEl.style.height = this.infographic.options.barHeight + "px"
    if (this.infographic.options.split) {
      this.values.forEach((value) => value.update())
    } else this.selectedSeries.update()
  }

  updateLabel() {
    this.selectedSeries.updateLabel()
  }
  newHighchartsChart() {
    const self = this
    const containerEl = document.createElement("div")
    containerEl.classList.add("field-chart-popup")
    containerEl.style.display = "none"
    const valueArray = self.values.map((value) => {
      return { x: value.date, y: parseInt(value.value) }
    })
    this.chart = createHighchartsChart(
      containerEl,
      valueArray,
      self.infographic.options.suffix
    )
    return containerEl
  }
}

class BarFieldHorizontal extends BarField {
  get absoluteBarHeight() {
    return (
      (this.selectedSeries.relSize * this.infographic.options.columnHeight) /
      100
    )
  }
  update() {
    this.domEl.style.left =
      (this.rankingPos * this.infographic.domEl.offsetWidth) /
        this.infographic.fields.length +
      "px"
    this.domEl.style.display = isNaN(this.selectedSeries.value)
      ? "none"
      : "block"
    this.domEl.style.width =
      this.infographic.domEl.offsetWidth / this.infographic.fields.length -
      this.infographic.options.barSpacing +
      "px"
    if (this.infographic.options.split) {
      this.values.forEach((value) => value.update())
    } else {
      this.barEl.style.height = this.absoluteBarHeight + "px"
    }
    this.updateLabel()
  }
}

export default class BarInfographic {
  constructor(rawData, elementId, customOptions) {
    const self = this
    const fields = prepareData(rawData)
    this.containerEl = document.getElementById(elementId)
    this.domEl = document.createElement("div")
    this.domEl.classList.add("infographic")
    this.containerEl.appendChild(this.domEl)
    this.options = new InfographicOptions(customOptions, this)
    this.allX = fields[0].values
      .map((value) => value.x)
      .filter(distinct)
      .sort((a, b) => b - a)
    this.seriesCount = this.allX.length
    this.series = {}
    this.tooltipEl = this.newTooltipEl()
    this._fields = this.options.horizontal
      ? fields.map((field) => new BarFieldHorizontal(field, self))
      : fields.map((field) => new BarField(field, self))
    this.xSelector =
      this.multipleDatasets && !this.options.split ? this.newXSelector() : null
    this.x = this.latestX // series // year
    this.setHeight()
    window.addEventListener("resize", this.updateFields.bind(this))

    if (!window.barInfographics) window.barInfographics = []
    window.barInfographics.push(this)

    function distinct(value, index, array) {
      return array.indexOf(value) === index
    }
  }

  // getters & setters
  get latestX() {
    return this.allX[0]
  }
  get multipleDatasets() {
    return this.allX.length > 1
  }
  get xMaxValue() {
    return this.fields
      .map((field) =>
        this.options.percent
          ? field.selectedSeries.percent
          : field.selectedSeries.compareableValue
      )
      .reduce((a, b) => Math.max(a, b))
  }
  get overallMaxValue() {
    return this.fields
      .map((field) => field.overallMaxValue)
      .reduce((a, b) => Math.max(a, b))
  }
  get fields() {
    if (this.options.noOrder) return this._fields
    else
      return this._fields.sort(
        (a, b) =>
          b.selectedSeries.compareableValue - a.selectedSeries.compareableValue
      )
  }
  get fieldHeight() {
    return this.options.split
      ? (this.options.barHeight / 2) * this.seriesCount +
          this.options.barSpacing
      : this.options.barHeight + this.options.barSpacing
  }
  get x() {
    return this._x
  }
  set x(arg) {
    this._x = arg
    if (this.xSelector) this.xSelector.value = arg
    this.updateFields()
  }

  // methods
  updateFields() {
    if (!this._fields) return
    this.fields.forEach((field) => field.update())
  }
  setHeight() {
    if (!this.options) return
    const newHeight = this.options.horizontal
      ? this.options.columnHeight
      : this._fields.length * this.fieldHeight + 20
    this.domEl.style.height = newHeight + "px"
  }
  newTooltipEl() {
    const result = document.createElement("div")
    result.classList.add("infographic-tooltip")
    if (
      document.getElementsByClassName("infographic-tooltip-container").length
    ) {
      document
        .getElementsByClassName("infographic-tooltip-container")[0]
        .appendChild(result)
    } else {
      const container = document.createElement("div")
      container.classList.add("infographic-tooltip-container")
      container.appendChild(result)
      document.body.appendChild(container)
    }
    return result
  }
  newXSelector() {
    let self = this
    const container = document.createElement("div")
    container.classList.add("infographic-x-selector-container")
    const selector = document.createElement("select")
    selector.classList.add("infographic-x-selector")
    this.allX.forEach((x) => {
      const optionEl = document.createElement("option")
      optionEl.innerHTML = x
      optionEl.value = x
      selector.appendChild(optionEl)
    })
    selector.addEventListener("change", () => (self.x = self.xSelector.value))
    container.appendChild(selector)
    this.domEl.parentElement.insertBefore(container, this.domEl)
    return selector
  }
}
