import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./arrow-down.scss"

const ArrowDown = ({ style, onClick, extraClasses, text = "" }) => (
  <button
    style={style}
    className={`arrow-down-container ${extraClasses}`}
    onClick={onClick}
    onKeyDown={onClick}
    data-message={text}
  >
    <FontAwesomeIcon icon="angle-down" />
  </button>
)

export default ArrowDown
