import chroma from "chroma-js"

const colorCount = 15

const colorScheme = chroma
  .cubehelix()
  .start(200)
  .rotations(3)
  .gamma(1)
  .lightness([0.3, 0.7])
  .scale() // convert to chroma.scale
  .correctLightness()
  .colors(colorCount) // number of tags

class TagManager {
  constructor(props) {
    this._tags = []
  }
  get tags() {
    return this._tags.slice()
  }
  getOrAddTag(tagName, clickCallback) {
    const existingTag = this._tags.find((t) => t.name === tagName)
    if (existingTag) return existingTag
    else {
      const tagId = this._tags.length
      const newTag = new Tag(tagName, clickCallback, tagId)
      this._tags.push(newTag)
      return newTag
    }
  }
}

export default TagManager

class Tag {
  constructor(tagName, clickCallback, tagId) {
    this.name = tagName
    this.value = this.name
    this.label = this.name
    const i = tagId < colorScheme.length ? tagId : tagId % colorScheme.length
    this.color = colorScheme[i]
    // console.log(this.color);
    this.tagEl = this.createTagEl()
    this.clickCallback = clickCallback
  }
  createTagEl() {
    let tagEl = document.createElement("span")
    tagEl.classList.add("map-tag")
    tagEl.style.backgroundColor = chroma(this.color).alpha(0.1).css()
    tagEl.style.color = this.color
    tagEl.innerHTML = this.name
    return tagEl
  }
}
