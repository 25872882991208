exports.categoryIds = [53, 57, 61, 65]

exports.categoryNames = ["crimea-de", "crimea-en", "crimea-ru", "crimea-uk"]

exports.metaInfos = [
  {
    langId: "de",
    title: "Archipel Krim",
    description: "Ein Multimedia-Dossier",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/11/crimea_default_de.png",
  },
  {
    langId: "en",
    title: "The Crimean Archipelago",
    description: "A Multimedia Dossier",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/11/crimea_default_en.png",
  },
  {
    langId: "ru",
    title: "Архипелаг Крым",
    description: "Мультимедийное досье",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/11/crimea_default_ru.png",
  },
  {
    langId: "uk",
    title: "Архіпелаг Крим",
    description: "Мультимедійне досьє",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2019/11/crimea_default_ua.png",
  },
]

exports.siteMetadata = {
  title: `Archipel Krim`,
  description: `Ein Multimedia-Dossier`,
  siteUrl: `https://crimea.dekoder.org/`,
  author: `dekoder.org`,
}

exports.pathPrefix = ""
exports.faviconPath = `src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout.js")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = `UA-66707787-2`
