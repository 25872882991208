import logoDefault from "./src/images/site-logo/crimea-logo-1.png"
import logoHover from "./src/images/site-logo/crimea-logo-4.png"

import dekoderLogo from "./src/images/logos/dekoder.png"
import dekoderLogoTransparent from "./src/images/logos/dekoder-black.svg"
import zoisLogoSolid from "./src/images/logos/zois-solid.svg"
import zoisLogo from "./src/images/logos/zois.svg"
import fsoLogo from "./src/images/logos/fso.png"
import volkswagenstiftungLogo from "./src/images/logos/volkswagenstiftung.svg"

import nostLogo from "./src/images/logos/n-ost.png"
import gdgLogo from "./src/images/logos/gdg.svg"
import zaboronaLogo from "./src/images/logos/zaborona.png"
import russlandAnalysenLogo from "./src/images/logos/russland-analysen.png"
import ukraineAnalysenLogo from "./src/images/logos/ukraine-analysen.png"

export {
  siteMetadata,
  metaInfos,
  restApi,
  categoryIds,
  categoryNames,
} from "./meta-data-node"

export const backgroundVideos = [
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/2_researchStationBlocksVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/2_researchStationBlocksPoster.jpg",
    hours: [9, 10, 11, 12, 13, 17, 18, 19],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/6_lighthouseFunicularVesselVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/6_lighthouseFunicularVesselPoster.jpg",
    hours: [14],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/9_quayKatsiveliVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/9_quayKatsiveliPoster.jpg",
    hours: [6, 7, 8, 20],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/3_researchStationBranchesVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/3_researchStationBranchesPoster.jpg",
    hours: [],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/8_lighthouseSeagullsVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/8_lighthouseSeagullsPoster.jpg",
    hours: [15],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/5_lighthouseFunicularLightsVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/5_lighthouseFunicularLightsPoster.jpg",
    hours: [21],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/1_researchStationGrassVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/1_researchStationGrassPoster.jpg",
    hours: [],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/4_lighthouseFunicularVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/4_lighthouseFunicularPoster.jpg",
    hours: [],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/7_lighthousePeopleVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/7_lighthousePeoplePoster.jpg",
    hours: [16],
  },
  {
    videoUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/10_pierKatsiveliVideo.mp4",
    posterUrl:
      "https://specials.dekoder.org/content/uploads/2019/12/10_pierKatsiveliPoster.jpg",
    hours: [22, 23, 0, 1, 2, 3, 4, 5],
  },
]

// 2nd logo = onMouseOver
export const siteLogos = [logoDefault, logoHover]

export const startPageLogos = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию",
      uk: "декодер | Розшифрувати Росію",
    },
    size: 100,
    url: "https://www.dekoder.org/",
    logo: dekoderLogoTransparent,
    invert: true,
  },
  {
    name: {
      de: "Zentrum für Osteuropa- und internationale Studien",
      en: "Centre for East European and International Studies",
      ru: "Центр восточноевропейских и международных исследований",
      uk: "Центр східноєвропейських і міжнародних досліджень",
    },
    size: 65,
    url: "https://www.zois-berlin.de/",
    logo: zoisLogoSolid,
    invert: false,
  },
  {
    name: {
      de: "Forschungsstelle Osteuropa an der Universität Bremen",
      en: "Research Centre for East European Studies at the University of Bremen",
      ru: "Исследовательский центр Восточной Европы при Бременском университете",
      uk: "Дослідницький центр Східної Європи при Бременському університеті",
    },
    size: 90,
    url: "https://www.forschungsstelle.uni-bremen.de/",
    logo: fsoLogo,
    invert: true,
  },
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию",
      uk: "декодер | Розшифрувати Росію",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.dekoder.org/",
    logo: dekoderLogo,
  },
  {
    name: {
      de: "Zentrum für Osteuropa- und internationale Studien",
      en: "Centre for East European and International Studies",
      ru: "Центр восточноевропейских и международных исследований",
      uk: "Центр східноєвропейських і міжнародних досліджень",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.zois-berlin.de/",
    logo: zoisLogo,
  },
  {
    name: {
      de: "Forschungsstelle Osteuropa an der Universität Bremen",
      en: "Research Centre for East European Studies at the University of Bremen",
      ru: "Исследовательский центр Восточной Европы при Бременском университете",
      uk: "Дослідницький центр Східної Європи при Бременському університеті",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.forschungsstelle.uni-bremen.de/",
    logo: fsoLogo,
  },
  {
    name: {
      de: "VolkswagenStiftung",
      en: "Volkswagen Foundation",
      ru: "Volkswagen Foundation",
      uk: "Volkswagen Foundation",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.volkswagenstiftung.de/",
    logo: volkswagenstiftungLogo,
  },
]

export const mediaPartners = [
  {
    name: {
      de: "n-ost",
      en: "n-ost",
      ru: "n-ost",
      uk: "n-ost",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.n-ost.de/",
    logo: nostLogo,
  },
  {
    name: {
      de: "Geschichte der Gegenwart",
      en: "Geschichte der Gegenwart",
      ru: "Geschichte der Gegenwart",
      uk: "Geschichte der Gegenwart",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://geschichtedergegenwart.ch/",
    logo: gdgLogo,
  },
  {
    name: {
      de: "Zaborona",
      en: "Zaborona",
      ru: "Заборона",
      uk: "Заборона",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "https://www.zaborona.com",
    logo: zaboronaLogo,
  },
  {
    name: {
      de: "Ukraine Analysen",
      en: "Ukraine Analysen",
      ru: "Ukraine Analysen",
      uk: "Ukraine Analysen",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "http://www.laender-analysen.de/ukraine/",
    logo: ukraineAnalysenLogo,
  },
  {
    name: {
      de: "Russland Analysen",
      en: "Russland Analysen",
      ru: "Russland Analysen",
      uk: "Russland Analysen",
    },
    text: {
      de: "",
      en: "",
      ru: "",
      uk: "",
    },
    url: "http://www.laender-analysen.de/russland/",
    logo: russlandAnalysenLogo,
  },
]
