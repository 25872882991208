import React, { useState, useContext, useEffect } from "react"
import ArrowDown from "../arrow-down"
import { LangContext } from "gatsby-source-dek-wp"
import "./start-page.scss"

function StartPage({ metaData, jumpUpDown, title, subtitle }) {
  const logos = metaData.startPageLogos
  const langId = useContext(LangContext).id
  const [statusMsg, setStatusMsg] = useState("")
  const [titleActive, setTitleActive] = useState(false)
  const [logosActive, setLogosActive] = useState(false)

  const activeStyles = { opacity: 1, pointerEvents: "auto" }
  const inactiveStyles = { opacity: 0, pointerEvents: "none" }

  useEffect(() => {
    setTimeout(() => setTitleActive(true), 1500)
    setTimeout(() => setLogosActive(true), 3000)
  }, [])

  return (
    <div className="start-page-wrapper">
      <div className={`status-message ${statusMsg ? " active" : ""}`}>
        {statusMsg}
      </div>
      <div
        style={logosActive ? activeStyles : inactiveStyles}
        className="start-page-logo-container"
      >
        {logos.map((logo, i) => (
          <div key={i} className="start-page-logo-flex-box">
            <a
              className="start-page-logo-link"
              href={logo.url}
              rel="noopener noreferrer"
              target="_blank"
              onMouseOver={() => setStatusMsg(logo.name[langId])}
              onFocus={() => setStatusMsg(logo.name[langId])}
              onMouseOut={() => setStatusMsg("")}
              onBlur={() => setStatusMsg("")}
            >
              <div
                className={`start-page-logo-wrapper${
                  logo.invert ? " invert" : ""
                }`}
                aria-label={logo.name[langId]}
              >
                <img
                  style={{ width: logo.size + "%" }}
                  alt={logo.name[langId]}
                  src={logo.logo}
                />
              </div>
            </a>
          </div>
        ))}
      </div>
      <div
        style={titleActive ? activeStyles : inactiveStyles}
        className="start-page-title-container"
      >
        <div className="start-page-title">{title}</div>
        <div className="start-page-subtitle">{subtitle}</div>
      </div>
      <ArrowDown
        style={titleActive ? activeStyles : inactiveStyles}
        onClick={jumpUpDown}
      />
    </div>
  )
}

export default StartPage
