import { useState, useRef, useEffect, useCallback } from "react"

const TRANSITION_SPEED = 300
const SIDE_MENU_OFFSET = 300

export function useCustomSwipeHanlder() {
  const [preloadId, setPreloadId] = useState(0)
  const allArticlesWrapper = useRef()
  const preloadById = useCallback((id) => {
    setPreloadId(id)
  }, [])

  useEffect(() => {
    // custom touch handler for swiping left/right
    const swipeHandler = new CustomSwipeHandler({
      domEl: allArticlesWrapper.current, // this.divEl.current,
      // closeAllMenus: this.closeAllMenus.bind(this),
      allowHorizontalSwipe: true, // this.state.allowHorizontalSwipe,
      preloadById,
    })
    swipeHandler.addListeners()
    return () => {
      swipeHandler.removeListeners()
    }
  }, [allArticlesWrapper, preloadById])

  return [allArticlesWrapper, preloadId]
}

export default class CustomSwipeHandler {
  constructor(props) {
    const {
      domEl,
      closeAllMenus,
      touchThreshold,
      allowHorizontalSwipe,
      preloadById,
    } = props
    this.resetState()
    this.domEl = domEl
    this.closeAllMenus = closeAllMenus
    this.touchThreshold = (touchThreshold || 0.15) * window.innerWidth
    this._allowHorizontalSwipe = allowHorizontalSwipe
    this.slidesContainer = {}
    this.preloadById = preloadById
  }

  get allowHorizontalSwipe() {
    return this._allowHorizontalSwipe
  }

  set allowHorizontalSwipe(val) {
    this._allowHorizontalSwipe = val
  }

  resetState() {
    this.state = {
      touchstartX: false,
      touchstartY: false,
      touchEndX: false,
      touchEndY: false,
      slidesContainerX: false,
      previousPreloaded: false,
      nextPreloaded: false,
    }
  }

  onTouchStart(e) {
    this.state.touchStartX = e.touches[0].clientX
    this.state.touchStartY = e.touches[0].clientY
    this.slidesContainer = document.getElementsByClassName(
      "fp-slidesContainer"
    )[0]
    this.state.slidesContainerStartX = getTranslateX(this.slidesContainer)
    // set transition time to 0 for smooth drag
    setTransition(0, this.slidesContainer)

    if (document.body.classList.contains("side-menu-open")) {
      this.fullpageContainer = document.getElementById("fullpage")
      setTransition(0, this.fullpageContainer)
    }
  }

  onTouchMove(e) {
    if (e.isMapTouchMove) return // don't change chapters when swiping within map
    if (!this.state.touchStartX) return
    // e.stopPropagation();
    this.state.touchEndX = e.changedTouches[0].clientX
    this.state.touchEndY = e.changedTouches[0].clientY
    const deltaX = this.state.touchEndX - this.state.touchStartX
    const deltaY = this.state.touchEndY - this.state.touchStartY
    if (Math.abs(deltaY) > Math.abs(deltaX)) return // no horizontal dran while scrolling
    this.handleDrag(deltaX)
    if (deltaX > this.touchThreshold && !this.state.previousPreloaded)
      this.preloadPrevious()
    else if (deltaX > -this.touchThreshold && !this.state.nextPreloaded)
      this.preloadNext()
  }

  onTouchEnd() {
    if (!this.state.touchEndX || !this.state.touchStartX) return // = simple click

    const deltaX = this.state.touchEndX - this.state.touchStartX
    const deltaY = this.state.touchEndY - this.state.touchStartY

    if (document.body.classList.contains("side-menu-open")) {
      setTransition("", this.fullpageContainer)
      if (
        deltaX > this.touchThreshold &&
        typeof this.closeAllMenus === "function"
      )
        this.closeAllMenus()
      this.resetFullpageContainer()
    } else {
      // reset transition time to default
      setTransition("", this.slidesContainer)
      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        this.resetSlidesContainer()
        return
      }

      if (this.allowHorizontalSwipe && Math.abs(deltaX) > this.touchThreshold) {
        // safari does not have "e.path"
        // if (e.path.find(el => el.classList ? el.classList.contains('map-container') : false)) return; // no slide actions within maps!

        if (deltaX > this.touchThreshold) {
          if (window.fullpage_api.getActiveSlide().isFirst)
            this.resetSlidesContainer()
          else {
            this.slideLeft()
          }
        } else if (deltaX < -this.touchThreshold) {
          if (window.fullpage_api.getActiveSlide().isLast)
            this.resetSlidesContainer()
          else {
            this.slideRight()
          }
        }
      } else this.resetSlidesContainer()
    }
  }

  handleDrag(deltaX) {
    if (document.body.classList.contains("side-menu-open")) {
      setRightOffset(
        Math.round(SIDE_MENU_OFFSET - deltaX),
        document.getElementById("fullpage")
      )
    } else {
      const newX = Math.round(this.state.slidesContainerStartX + deltaX)
      setTranslateX(newX, this.slidesContainer)
    }
  }

  resetSlidesContainer() {
    // drag: if chapter switch threshold was not reached, reset slides container to initial position
    setTranslateX(this.state.slidesContainerStartX, this.slidesContainer)
    this.resetState()
  }

  resetFullpageContainer() {
    setRightOffset("", this.fullpageContainer)
  }

  slideRight() {
    const newX = Math.round(
      this.state.slidesContainerStartX - window.innerWidth
    )
    setTranslateX(newX, this.slidesContainer) // faster than fullpage_api
    setTimeout(window.fullpage_api.moveSlideRight, TRANSITION_SPEED) // "callback" to trigger connected functions
    this.resetState()
  }

  slideLeft() {
    const newX = Math.round(
      this.state.slidesContainerStartX + window.innerWidth
    )
    setTranslateX(newX, this.slidesContainer) // faster than fullpage_api
    setTimeout(window.fullpage_api.moveSlideLeft, TRANSITION_SPEED) // "callback" to trigger connected functions
    this.resetState()
  }

  preloadPrevious() {
    this.preloadById(window.fullpage_api.getActiveSlide().index)
    this.state.previousPreloaded = true
  }
  preloadNext() {
    this.preloadById(window.fullpage_api.getActiveSlide().index + 2)
    this.state.nextPreloaded = true
  }

  addListeners() {
    this.domEl.addEventListener("touchstart", this.onTouchStart.bind(this))
    this.domEl.addEventListener("touchmove", this.onTouchMove.bind(this))
    this.domEl.addEventListener("touchend", this.onTouchEnd.bind(this))
  }

  removeListeners() {
    this.domEl.removeEventListener("touchstart", this.onTouchStart.bind(this))
    this.domEl.removeEventListener("touchmove", this.onTouchMove.bind(this))
    this.domEl.removeEventListener("touchend", this.onTouchEnd.bind(this))
  }
}

// custom handler for horizontal scrolling
/* this.divEl.current.addEventListener('wheel', e => {
        e.preventDefault();
        e.stopPropagation();
        // e.preventDefault();
        // e.stopPropagation();
        // if(window.prevent) e.preventDefault();
        this.divEl.current.scrollTop = this.divEl.current.scrollTop + e.deltaY;
        // if (e.deltaY > e.deltaX) return;
        if (e.deltaX > 10 || e.deltaX < -10) {
          console.log('x');
          if (e.deltaX > 30) debounce(window.fullpage_api.moveSlideRight, 500);
          if (e.deltaX < -30) debounce(window.fullpage_api.moveSlideLeft, 500);
        }
      }, true);*/

function setTransition(val, el) {
  el.style.transition = val === "" ? "" : `all ${val}s ease`
}

function setTranslateX(xPos, el) {
  el.style.transform = `translate3d(${xPos}px, 0, 0)`
}

function getTranslateX(el) {
  return parseInt(
    el.style.transform
      .replace("translate3d(", "")
      .replace(")", "")
      .split(",")[0]
      .replace("px", "")
  )
}

function setRightOffset(val, el) {
  el.style.right = val === "" ? "" : `${val}px`
}
