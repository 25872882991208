import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import ArrowDown from "../arrow-down/arrow-down"
import { LangContext } from "gatsby-source-dek-wp"

const nextChapter = {
  de: "Weiter",
  en: "Next",
  ru: "Дальше",
  ua: "Далі",
}

export default function ArticleBottom({ setVideoProps, nextArticleSlug }) {
  const langId = useContext(LangContext).id
  const [refContainer, inViewContainer] = useInView({ threshold: 0 })
  const [refBottom, inViewBottom] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inViewContainer) {
      if (typeof window.fullpage_api.getActiveSlide !== "function") return
      if (!window.fullpage_api.getActiveSlide()) return
      const newVideoIndex = window.fullpage_api.getActiveSlide().index
      setVideoProps({
        playing: true,
        index: newVideoIndex,
      })
      document.body.classList.add("article-end-reached")
    } else {
      setVideoProps((p) => ({ ...p, playing: false }))
      document.body.classList.remove("article-end-reached")
    }
  }, [inViewContainer, setVideoProps])

  return (
    <>
      <div className="article-padding-bottom-container" ref={refContainer}>
        <Link to={`/${nextArticleSlug}`} onClick={(e) => e.preventDefault()}>
          <ArrowDown
            extraClasses={inViewBottom ? "bottom-reached" : ""}
            text={nextChapter[langId]}
            onClick={() => window.fullpage_api.moveSlideRight()}
          />
        </Link>
        <div
          ref={refBottom}
          style={{
            position: "absolute",
            bottom: 0,
            margin: "0 auto",
            height: "50px",
            width: "100%"
          }}
        />
      </div>
    </>
  )
}
