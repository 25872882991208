import { createContext, useEffect, useRef } from "react"

export const BREAKPOINT_MOBILE = 850
export const BREAKPOINT_TABLET = 1250
export const isMobile = () => window.innerWidth < BREAKPOINT_MOBILE

export const LangContext = createContext()

export function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    if (!value) return
    ref.current = value
  }, [value])
  return ref.current
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1) // word.slice(1).toLowerCase()
}

export function capitalizeFirstLetterOfEachWord(string) {
  return string
    .split(" ")
    .map(capitalizeFirstLetter) // word.slice(1).toLowerCase()
    .join(" ")
}

export function dateToLocaleString(date, locale) {
  const options = { year: "numeric", month: "long" }
  return date.toLocaleDateString(locale, options)
}

export function isTouchDevice() {
  return "ontouchstart" in document.documentElement
}

export function decodeHtmlEntitiesToUnicode(str) {
  return str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
}

export function isDekoderLink(url) {
  const re1 = /https:\/\/www\.dekoder\.org\/de\/(.*?)/g
  const re2 = /https:\/\/www\.dekoder\.org\/ru\/(.*?)/g
  if (url.match(re1) || url.match(re2)) return true
}
