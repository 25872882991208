import React, { useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./footer.scss"

const translations = {
  projectPartners: {
    de: "Kooperationspartner und Förderer",
    en: "Cooperation Partners and Sponsors",
    ru: "Партнеры и спонсоры",
    uk: "Cooperation Partners and Sponsors",
  },
  mediaPartners: {
    de: "Medienpartner",
    en: "Media Partners",
    ru: "Медиа-партнеры",
    uk: "Media Partners",
  },
}

export default function Footer({ metaData }) {
  const langId = useContext(LangContext).id
  const { projectPartners, mediaPartners } = metaData
  return (
    <>
      <footer className="ph-footer">
        © 2019 dekoder gGmbH –{" "}
        <a
          href="https://www.dekoder.org/kontakt"
          rel="noopener noreferrer"
          target="_blank"
        >
          Kontakt
        </a>{" "}
        –{" "}
        <a
          href="https://www.dekoder.org/impressum"
          rel="noopener noreferrer"
          target="_blank"
        >
          Impressum
        </a>
        <div className="ph-footer-iconmenu">
          <ul className="ph-footer-iconmenu-list">
            <li className="ph-iconmenu-item">
              <a
                className="ph-iconmenu-link"
                title="Facebook"
                href="https://www.facebook.com/dekoder.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                <span className="ph-iconmenu-label">Facebook</span>
              </a>
            </li>
            <li className="ph-iconmenu-item">
              <a
                className="ph-iconmenu-link"
                title="Twitter"
                href="https://twitter.com/dekoder_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
                <span className="ph-iconmenu-label">Twitter</span>
              </a>
            </li>
            <li className="ph-iconmenu-item">
              <a
                className="ph-iconmenu-link"
                title="RSS Feed"
                href="https://www.dekoder.org/rss_de_all.xml"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="rss" />
                <span className="ph-iconmenu-label">RSS Feed</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <div className="ph-footer-logos">
        <p className="ph-footer-logos-text">
          {translations.projectPartners[langId]}:
        </p>
        {projectPartners.map((partner) => (
          <a
            key={"footer-coop-partner-" + partner.name[langId]}
            className="ph-footer-logo-link"
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
            data-popup={
              partner.name[langId] +
              (partner.text[langId] ? "<br/>" + partner.text[langId] : "")
            }
          >
            <img
              className="ph-footer-logo"
              alt={partner.name[langId]}
              src={partner.logo}
            />
          </a>
        ))}
        {mediaPartners.length && (
          <p className="ph-footer-logos-text">
            {translations.mediaPartners[langId]}:
          </p>
        )}
        {mediaPartners.map((partner) => (
          <a
            key={"footer-media-partner-" + partner.name[langId]}
            className="ph-footer-logo-link"
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
            data-popup={
              partner.name[langId] +
              (partner.text[langId] ? "<br/>" + partner.text[langId] : "")
            }
          >
            <img
              className="ph-footer-logo"
              alt={partner.name[langId]}
              src={partner.logo}
            />
          </a>
        ))}
      </div>
    </>
  )
}
