import React, { useState, useEffect } from "react"
import SocialBar from "../social-bar"

export default function ArticleTeaser({ children }) {
  const [url, setUrl] = useState()
  useEffect(() => {
    setUrl(window.location.href)
  }, [])
  return (
    <>
      <p className="article-teaser">{children}</p>
      {url && <SocialBar url={url} />}
    </>
  )
}
