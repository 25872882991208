import React from "react"
import { Link } from "gatsby"
import "./chapter-item.scss"

const ChapterItem = ({
  id,
  href,
  selected,
  comingSoon,
  title,
  onClick,
  extraClasses,
}) => (
  <li key={id} className={`chapter-item ${extraClasses}`}>
    <Link
      to={href}
      className={`${selected ? "selected" : ""} ${
        comingSoon ? "coming-soon" : ""
      }`}
    >
      <button
        // data-index={id}
        data-title={title} // {article.ref ? article.ref.title : ''}
        className={`chapter-option ${selected ? "selected" : ""} ${
          comingSoon ? "coming-soon" : ""
        }`}
        onClick={onClick}
      >
        {title}
      </button>
    </Link>
  </li>
)

export default ChapterItem
