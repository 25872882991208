import React, { useMemo, cloneElement } from "react"
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser"

import { v4 as uuidv4 } from "uuid"

import ContentContainer from "../components/content-container/content-container"
import Map from "../components/map/map"
import ArticleTeaser from "../components/article/article-teaser"
import Infographic from "../components/infographic/infographic"
import Link from "../components/link/link"
import AnnotatedText from "../components/annotated-text/annotated-text"
import Toc from "../components/toc/toc"
import { imageTransformer } from "gatsby-source-dek-wp"

const transformers = [
  imageTransformer
]

export function transform(node, i, options = {}, transformHook) {
  const classList = nodeClassList(node)

  // register custom components (i. e. TruppenabzugMap)
  if (typeof transformHook === "function") {
    const match = transformHook(node, i, options, classList, uuidv4())
    if (match) return match
  }

  if (node.name === "style") return null
  if (node.type !== "tag") return

  if (node.name === "a")
    return (
      <Link
        key={uuidv4()}
        href={node.attribs.href}
        children={getChildren(node)}
      />
    )
  if (node.name === "toc") return <Toc key={uuidv4()} />
  if (classList.includes("article-teaser"))
    return <ArticleTeaser key={uuidv4()} children={getChildren(node)} />
  if (classList.includes("dek-infographic")) {
    return (
      <Infographic
        key={uuidv4()}
        articleEl={options.articleEl}
        dataUrl={node.attribs["data-url"]}
        options={node.attribs.options}
        dataJson={node.attribs["data-json"]}
      />
    )
  }
  if (node.name === "p") {
    if (
      options.childLevel === 1 &&
      i === 0 &&
      node.parent &&
      node.parent.name !== "blockquote"
    )
      return <AnnotatedText key={uuidv4()} children={getChildren(node)} />
  }
  if (classList.includes("wp-block-ugb-container")) {
    const firstLevel = !options.childLevel
    const firstElRef = React.createRef()
    const children = getChildren(node, { ...options, firstElRef })[0].props
      .children[0].props.children[0].props.children[0].props.children[0].props
      .children
    const hasStickyImg =
      children.length &&
      children[0].props.className === "wp-block-image" &&
      children[0].props.children[0].props.className !== "alignleft"
    const hasStickyMap = children[0].type === Map
    const hasBlockquote =
      children.length && children[0].props.className === "wp-block-quote"
    const hasTextblock = children[0].type === AnnotatedText

    // ref, element, sticky
    if (firstLevel)
      return (
        <ContentContainer
          className={`
          ${classList.join(" ")}
          ${hasStickyImg ? " container-with-sticky-image" : ""}
          ${hasStickyMap ? " container-with-sticky-map" : ""}
          ${hasBlockquote ? " container-with-blockquote" : ""}
          ${hasTextblock ? " container-with-annotations" : ""}
        `}
          key={uuidv4()}
          articleEl={options.articleEl}
          children={children}
          firstElRef={firstElRef}
        />
      )
  }
  if (classList.includes("dek-map"))
    return (
      <Map
        ref={options.firstElRef}
        key={uuidv4()}
        sticky={true}
        articleEl={options.articleEl}
      />
    )


  for (let x = 0; x < transformers.length; x++) {
    const t = transformers[x]
    const comp = t(node, 0, { classList, children: getChildren(node) })
    if (comp) return cloneElement(comp, { ...comp.props, key: uuidv4() })
  }
}

const transformer = (content, customOptions, transformHook) => {
  const patchedTransform = (node, i) =>
    transform(node, i, customOptions, transformHook)
  if (typeof content === "object" && content !== null) {
    if (content.length) {
      // array
      return content.map((n, i) => convertNodeToElement(n, i, patchedTransform))
    } // node
    else return convertNodeToElement(content, 0, patchedTransform)
  }
  // string
  else return ReactHtmlParser(content, { transform: patchedTransform })
}

export default transformer


export function useTransformer(contentString) {
  const transformedContent = useMemo(() => transformer(contentString), [
    contentString,
  ])
  return transformedContent
}

/**
 * helper
 */

function nodeClassList(node) {
  if (!node.attribs) return []
  if (!node.attribs.class) return []
  return node.attribs.class.split(" ")
}

/*function hasMatch(array, regex) {
  return array.reduce((acc, c) => acc || c.match(regex), false);
}*/

function getChildren(node, options = {}) {
  const prevChildLevel = options.childLevel || 0
  const patchedOptions = { ...options, childLevel: prevChildLevel + 1 }
  const comp = transformer(node, patchedOptions)
  if (!comp) return null
  return (comp.props.children || []).filter((c) => c)
}
