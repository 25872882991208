import React, { useContext } from "react"
import { navigate } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage"
import { BREAKPOINT_MOBILE } from "../../helpers"
import { LangContext } from "gatsby-source-dek-wp"

import "./fullpage.scss"

function resetScollPositions() {
  // firefox bugfix
  // console.log('RESETTING SCROLL POSITIONS');
  if (typeof document === "undefined") return
  document.body.scrollTo(0, 0)
  const slidesContainer = document.querySelector(".fp-slides")
  if (slidesContainer) slidesContainer.scrollTo(0, 0)
}

export default function FullpageWrapper({
  children,
  setVideoProps,
  articles,
  selected,
  prevSelected,
  pageContext,
  firstLoad,
}) {
  const setVideoPlaying = (playing) => setVideoProps((p) => ({ ...p, playing }))
  const lang = useContext(LangContext)
  const onLeave = (source, destination) => {
    // console.log("ON LEAVE", destination)
    if (destination.index === 0) {
      // up
      if (!firstLoad) setVideoPlaying(true)
      window.fullpage_api.setKeyboardScrolling(true, "down")
      window.fullpage_api.setAllowScrolling(true, "down")
      if (!firstLoad && selected?.slug !== lang.id) navigate(`/${lang.id}`)
    } else {
      // down
      if (!firstLoad) setVideoPlaying(false)
      window.fullpage_api.setKeyboardScrolling(false, "up")
      window.fullpage_api.setAllowScrolling(false)
      if (!firstLoad && prevSelected && pageContext.slug !== prevSelected.slug) navigate(`/${prevSelected.slug}`)
    }
    resetScollPositions()
  }
  const onSlideLeave = (section, source, destination) => {
    // console.log("ON SLIDE LEAVE", destination)
    const newArticle = articles.find((a) => a.orderId === destination.index + 1)
    if (newArticle && pageContext.slug !== newArticle.slug) {
      navigate(`/${newArticle.slug}`)
    }
    resetScollPositions()
  }
  return (
    <ReactFullpage
      // pluginWrapper={pluginWrapper}
      // navigation
      licenseKey="1E178746-D4564385-AC5B7800-44A0D44E"
      css3={true}
      // autoScrolling={true}
      /* fixedElements={ //  ,
        ".language-button, .menu-button, .background-video-container, .background-video-volume-container, .background-video-control-container"
      } */
      // scrollBar={false}
      scrollingSpeed={getTransitionSpeed()}
      easingcss3="ease-out"
      lazyLoading={false}
      sectionSelector={".section"}
      normalScrollElements={".slide, .chapter-menu"}
      slidesNavigation={true}
      slidesNavPosition="top"
      verticalCentered={false}
      loopHorizontal={false}
      bigSectionsDestination={"top"}
      fitToSection={false}
      // keyboardScrolling={false}
      onLeave={onLeave}
      onSlideLeave={onSlideLeave}
      afterLoad={resetScollPositions}
      afterSlideLoad={resetScollPositions}
      render={() => children}
    />
  )
}

function getTransitionSpeed() {
  if (typeof window === "undefined") return 600
  return window.innerWidth <= BREAKPOINT_MOBILE ? 300 : 600
}
