import React, { useMemo, useState, useEffect } from "react"
import ChapterItem from "./chapter-item"
import "./chapter-menu.scss"
import { BREAKPOINT_TABLET } from "../../helpers"

function ChapterMenu({ closeAllMenus, selected, articles, open }) {
  function handleChapterClick() {
    // disable slide transition effects on mobile devices
    if (window.innerWidth <= BREAKPOINT_TABLET) {
      window.fullpage_api.setScrollingSpeed(0)
      closeAllMenus()
    }
  }
  const menuCategories = useMemo(() => {
    return articles.reduce((acc, article) => {
      if (article.menuCategory && !acc.includes(article.menuCategory))
        acc = [...acc, article.menuCategory]
      return acc
    }, [])
  }, [articles])
  const [selectedCategory, setSelectedCategory] = useState(
    selected?.menuCategory
  )
  useEffect(() => {
    setSelectedCategory(selected?.menuCategory)
  }, [selected])

  const pagesWithoutCategory = useMemo(
    () => articles.filter((a) => !a.menuCategory && a.pageType !== "about"),
    [articles]
  )

  const aboutPages = useMemo(() => articles.filter((a) => a.pageType === "about"), [
    articles,
  ])
  // console.log(articles, menuCategories, selected)
  return (
    <nav className={"chapter-menu" + (open ? " open" : " closed")}>
      <ul className="chapter-list">
        {pagesWithoutCategory.map((article, i) => (
          <ChapterItem
            key={`unsorted_${article.orderId}_${i}`}
            id={`unsorted_${article.orderId}_${i}`}
            extraClasses="toc-page"
            href={`/${article.slug}`}
            selected={article.orderId === selected?.orderId}
            comingSoon={article.comingSoon}
            title={article.menuTitle}
            onClick={handleChapterClick}
          />
        ))}
        {menuCategories.map((category, i) => {
          const subPages = articles.filter((a) => a.menuCategory === category)
          const categorySelected = selectedCategory === category
          return (
            <span
              key={category}
              className={`chapter-block ${categorySelected ? "selected" : ""}`}
            >
              <li className="chapter-category-item">
                <button
                  onClick={() => {
                    setSelectedCategory(category)
                  }}
                  className={`chapter-category ${
                    categorySelected ? "selected" : ""
                  }`}
                >
                  {category}
                  {!categorySelected ? "" : " →"}
                </button>
              </li>
              <span className="sub-chapters">
                {subPages.map((article, i) => (
                  <ChapterItem
                    key={`sub_${article.orderId}_${i}`}
                    id={`sub_${article.orderId}_${i}`}
                    href={`/${article.slug}`}
                    selected={article.orderId === selected?.orderId}
                    comingSoon={article.comingSoon}
                    title={article.menuTitle}
                    onClick={handleChapterClick}
                  />
                ))}
              </span>
            </span>
          )
        })}
        {aboutPages.map((article, i) => (
          <ChapterItem
            key={`about_${article.orderId}_${i}`}
            id={`about_${article.orderId}_${i}`}
            href={`/${article.slug}`}
            selected={article.orderId === selected?.orderId}
            comingSoon={article.comingSoon}
            title={article.menuTitle}
            onClick={handleChapterClick}
          />
        ))}
      </ul>
    </nav>
  )
}

export default ChapterMenu
