import React, { useRef, useContext, useEffect, useState } from "react"
import { useBodyClass } from "../../helpers"
import { LangContext, usePreview } from "gatsby-source-dek-wp"
import useKeyboardScroll from "./keyboard-scroll"
import { usePreloadedPopups } from "gatsby-plugin-popup-preloader"
import useAnchorChecker from "./anchor-checker"
import { useDonEncPageView, PAGE_BODY_CLASS } from "../app/donation-encourager"
import ProgressBar from "../progress-bar"
import ArticleMeta from "./article-meta"
import ArticleBottom from "./article-bottom"
import CommentSection from "../comment-section"
import NewsletterForm from "../newsletter-form"
import SocialBar from "../social-bar"
import Footer from "../footer"
import transformer from "../../modules/transformer"

import "./article-wrapper.scss"
import "./article.scss"

function Article({
  data,
  staticContext,
  metaData,
  isSelected,
  jumpUpDown,
  setVideoProps,
  nextArticleSlug,
}) {
  const lang = useContext(LangContext)
  const divEl = useRef()
  const { siteMetadata, transformHook, restApi } = metaData
  const { slug, darkBackground } = data
  const url = `${siteMetadata.siteUrl}${slug}`

  useAnchorChecker(isSelected)
  useKeyboardScroll(isSelected, divEl, jumpUpDown)
  useBodyClass(isSelected && darkBackground ? "dark" : "")
  useScrollToTop(isSelected, divEl)

  const {
    author,
    photoEditor,
    translator,
    date,
    dataVisualization,
    comingSoon,
    pageType,
    content,
    postId,
    popups,
  } = staticContext // pageContext

  usePreloadedPopups(popups)

  const contentState = usePreview(content, postId, restApi)
  const [transformedContent, setTransformedContent] = useState("")
  useEffect(() => {
    if (!contentState) return
    const newTransformedContent = transformer(contentState, { articleEl: divEl }, transformHook)
    setTransformedContent(newTransformedContent)
  }, [contentState, divEl, transformHook])

  useDonEncPageView(content)

  return (
    <div ref={divEl} className="article-scroll-wrapper">
      <article
        className={`article-wrapper-inner ${isSelected ? " selected" : ""}`}
      >
        <ProgressBar scrollWrapper={divEl.current} />
        <div className="article-title">
          <h1>{data.title}</h1>
        </div>
        {pageType !== "toc" && (
          <ArticleMeta
            author={author}
            photoEditor={photoEditor}
            translator={translator}
            date={date}
            dataVisualization={dataVisualization}
          />
        )}
        <div
          className={`${PAGE_BODY_CLASS}${
            comingSoon || pageType === "toc" ? " no-donation-encourager" : ""
          }`}
        >
          {transformedContent}
        </div>
        {data.comingSoon && <NewsletterForm lang={lang} />}
        <SocialBar url={url} />
        {isSelected && data.hasDiscussion && (
          <div id="discussion">
            <CommentSection
              initiallyOpen={true /*data.discussionOpenByDefault*/}
              id={data.orderId}
              intUrl={url}
              intTitle={data.menuTitle}
            />
          </div>
        )}
      </article>
      {pageType === "about" ? (
        <Footer metaData={metaData} />
      ) : (
        isSelected && (
          <ArticleBottom
            setVideoProps={setVideoProps}
            nextArticleSlug={nextArticleSlug}
          />
        )
      )}
    </div>
  )
}

export default Article

function useScrollToTop(isSelected, refEl) {
  useEffect(() => {
    if (!isSelected) return
    refEl.current.focus()
    refEl.current.scrollTo({ top: 0 })
  }, [isSelected, refEl])
}
