import { useEffect } from "react"

export function useJumpToSelected(slug, allArticles, firstLoad) {
  useEffect(() => {
    const jumpToSlug = (slug, silent = false) => {
      const article = allArticles.find((a) => a.slug === slug)
      const toStartPage = !article
      const section = toStartPage ? 1 : 2
      const slideId = toStartPage ? undefined : article.orderId - 1
      // console.log("JUMP TO SLUG", slug, silent, section, slideId);
      if (silent) window.fullpage_api.silentMoveTo(section, slideId)
      else window.fullpage_api.moveTo(section, slideId)
    }
    jumpToSlug(slug, !!firstLoad)
  }, [slug, allArticles, firstLoad])
}