import React from "react"
import Select from "react-select"
import chroma from "chroma-js"

const containerStyles = {
  position: "absolute",
  top: "15px",
  left: "45px",
  zIndex: "100",
  width: "calc(100% - 55px)",
}

const selectStylesSearch = {
  container: (provided, state) => ({
    ...provided,
    width: "50%",
    padding: "0 5px",
    display: "inline-block",
    verticalAlign: "top",
    fontSize: "0.8em",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "30px",
    borderRadius: 0,
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
    borderWidth: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // height: '30px',
    fontFamily: "Roboto Condensed",
    padding: "1px 8px",
  }),
  multiValue: (styles, { data }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: "4px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "4px",
  }),
}

const selectStylesTags = {
  container: (provided, state) => ({
    ...provided,
    width: "50%",
    padding: "0 5px",
    display: "inline-block",
    verticalAlign: "top",
    fontSize: "0.8em",
  }),
  control: (provided, state) => ({
    ...provided,
    // height: '30px',
    minHeight: "30px", // 40
    borderRadius: 0,
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
    borderWidth: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // height: '30px',
    fontFamily: "Roboto Condensed",
    padding: "1px 8px",
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      color: color.css(),
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: "4px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "4px",
  }),
}

const MapSearch = (props) => {
  const {
    tagManager,
    selectedTags,
    points,
    selectedPoint,
    onMapSearchChange,
    onMapFilterChange,
  } = props
  const filterOptions = tagManager.tags.map((t) => {
    return { value: t.name, label: t.name, color: t.color }
  })
  const selectedFilterOptions = (selectedTags || []).map((st) => {
    return { value: st.value, label: st.label, color: st.color }
  })
  const pointOptions = points.map((p) => {
    return { value: p.name, label: p.name }
  })
  const selectedPointOption = pointOptions.find(
    (o) => o.value === selectedPoint.name
  )
  return (
    <div className="map-search-container" style={containerStyles}>
      <Select // tag filter
        options={filterOptions}
        styles={selectStylesTags}
        value={selectedFilterOptions}
        onChange={onMapFilterChange}
        isClearable={true}
        isMulti
        placeholder="Filter ..."
      />
      <Select // list
        options={pointOptions}
        styles={selectStylesSearch}
        value={selectedPointOption}
        onChange={onMapSearchChange}
        isClearable={true}
      />
    </div>
  )
}

export default MapSearch
