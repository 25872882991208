import { useEffect } from "react"

const KEYBOARD_SCROLL_DELTA = 100

export default function useKeyboardScroll(selected, refEl, jumpUpDown) {
  useEffect(() => {
    function onKeyDown(e) {
      if (e.key === "ArrowUp") {
        // console.log("arrowUp");
        if (refEl.current.scrollTop <= 0) {
          jumpUpDown()
          return
        }
        refEl.current.scrollTop =
          refEl.current.scrollTop - KEYBOARD_SCROLL_DELTA
      } else if (e.key === "ArrowDown") {
        // console.log("arrowDown");
        refEl.current.scrollTop =
          refEl.current.scrollTop + KEYBOARD_SCROLL_DELTA
      }
    }
    if (!selected) return
    document.addEventListener("keydown", onKeyDown)
    return () => document.removeEventListener("keydown", onKeyDown)
  }, [selected, jumpUpDown, refEl])
}
