import React, { useCallback, createRef, useMemo, useEffect } from "react"
import { Waypoint } from "react-waypoint"

function ContentContainer({ children, firstElRef, className, articleEl }) {
  const firstEl = children[0]
  const secondaryEls = useMemo(() => {
    return children.slice(1).map((el) => ({ el, ref: createRef() }))
  }, [children])
  const handleEnter = useCallback(
    (el) => {
      if (firstElRef.current) {
        if (typeof firstElRef.current.handleEnter === "function") {
          if (el.current.children[0].classList.contains("extra")) return
          firstElRef.current.handleEnter(el)
        }
      }
    },
    [firstElRef]
  )
  useEffect(() => {
    if (firstElRef.current && firstElRef.current.isMap)
      firstElRef.current.generatePoints(secondaryEls)
  }, [firstElRef, secondaryEls])

  return (
    <div className={className}>
      {firstEl}
      <div className="container-secondary-elements">
        {secondaryEls.map(({ el, ref }, i) => {
          return (
            <Waypoint
              key={i}
              scrollableAncestor={articleEl.current}
              bottomOffset={
                typeof window !== "undefined" ? window.innerHeight / 3 : 400
              }
              onEnter={() => handleEnter(ref)}
            >
              <div className="p-wrapper" ref={ref}>
                {el}
              </div>
            </Waypoint>
          )
        })}
      </div>
    </div>
  )
}

export default ContentContainer
