import React from "react"
import { App, LayoutStandalone } from "../../shared/"
import * as metaData from "../meta-data-es6"

const Layout = (props) => {
  if (props.pageContext.layout === "no-layout") {
    return <LayoutStandalone {...props} metaData={metaData} />
  } else {
    return <App {...props} metaData={metaData} />
  }
}

export default Layout
