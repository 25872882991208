import React, { useRef, useEffect, useMemo, useState } from "react"
import { popupAggregator } from "gatsby-plugin-popup-preloader"
import { isDekoderLink, isTouchDevice } from "../../helpers"
import { navigate } from "gatsby"
import { useTransformer } from "../../modules/transformer"
import "./link-popups.scss"

function InternalLink(props) {
  const { children, href } = props
  function onLinkClick(e) {
    e.preventDefault()
    e.stopPropagation()
    const slug = href.replace("./", "")
    navigate(`/${slug}`)
  }
  return (
    <a href={href} onClick={onLinkClick}>
      {children}
    </a>
  )
}

function DekoderLink(props) {
  const { children, href } = props
  const [expanded, setExpanded] = useState(false)
  const ref = useRef()
  const linkType = useLinkType(href)
  const dekPopupProps = useDekoderPopup(linkType, href)
  // console.log(dekPopupProps)
  const { title, text = "", type } = dekPopupProps
  const transformedText = useTransformer(text.replace("<p>", "").replace("</p>", ""))
  return (
    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={`a-wrapper has-popup ${type} ${expanded ? "active" : ""}`}
      onMouseEnter={(e) => {
        if (isTouchDevice()) return
        setExpanded(true)
      }}
      onMouseLeave={(e) => {
        if (isTouchDevice()) return
        setExpanded(false)
      }}
    >
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        onClick={(e) => {
          if (isTouchDevice()) {
            e.preventDefault()
            setExpanded((exp) => !exp)
          }
        }}
      >
        {children}
      </a>
      <span className="popup">
        <span className="popup-wrapper">
          {!!title && type !== "blurb" && <strong>{title}: </strong>}
          {transformedText}
          {type !== "blurb" && (
            <a
              href={href}
              className="dekoder-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              → Mehr dazu auf dekoder.org
            </a>
          )}
        </span>
      </span>
    </span>
  )
}

export default function Link(props) {
  const { children, href } = props
  if (href.match(/^.\/[a-z\-_0-9]*$/i)) return <InternalLink {...props} />
  if (isDekoderLink(href)) return <DekoderLink {...props} />
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

function useDekoderPopup(type, href) {
  const existingPopup = popupAggregator.getPopupByUrl(href)
  const initialState = existingPopup
    ? {
        type: existingPopup.type,
        text: existingPopup.text,
        title: existingPopup.title,
        url: existingPopup.url,
      }
    : {}
  const [popup, setPopup] = useState(initialState) //  ||
  // console.log(popupAggregator.getPopupByUrl(href));

  useEffect(() => {
    if (type !== "dekoder-link") return
    // console.log('getting ', href);
    let isMounted = true
    const getPopupData = async () => {
      await delay(200)
      const p = await popupAggregator.addByUrl(href)
      // console.log("LOADED", p)
      if (!isMounted) {
        // console.log('COMP NOT MOUNTED')
        return
      }
      setPopup({
        type: p.type,
        text: p.text,
        title: p.title,
        url: p.url,
      })
    }
    getPopupData()
    // return () => console.log('LINK WILL UNMOUNT');
    return () => (isMounted = false)
  }, [href, type])

  return popup
}

function useLinkType(href) {
  return useMemo(() => getLinkType(href), [href])
}

function getLinkType(href) {
  if (href.match(/^\/[a-z\-_0-9]*$/i)) return "internal-link"
  if (href.match(/^annotation:/)) return "annotation"
  if (isDekoderLink(href)) return "dekoder-link"
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
