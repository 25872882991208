import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./annotated-text.scss"

export default function AnnotatedText({ children }) {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((o) => !o)
  return (
    <div className={`annotated-text ${open ? " open" : ""}`}>
      <p>{children}</p>
      <button
        className="annotated-text-button"
        onClick={toggle}
        onKeyDown={toggle}
      >
        <FontAwesomeIcon icon={open ? "minus" : "plus"} />
      </button>
    </div>
  )
}
