import React, { useState, useEffect, useCallback, useContext } from "react"
import ChapterMenu from "../chapter-menu"
import { BREAKPOINT_TABLET } from "../../helpers"
import { LangContext } from "gatsby-source-dek-wp"
import LanguageMenu from "../language-menu"
import "./header.scss"
import { Portal } from "react-portal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Header({ setVideoProps, languages, setLang, articles, selected }) {
  const [chapterMenuOpen, setChapterMenuOpen] = useState(false)
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false)
  const lang = useContext(LangContext)

  const openMenu = useCallback(() => {
    document.getElementById("fullpage").style.transition = "right .3s ease-out"
    document.body.classList.add("side-menu-open")
    if (window.innerWidth < BREAKPOINT_TABLET)
      setVideoProps((p) => ({ ...p, playing: true }))
  }, [setVideoProps])
  const closeMenu = useCallback(() => {
    document.getElementById("fullpage").style.transition = "right .3s ease-out"
    document.body.classList.remove("side-menu-open")
    if (
      window.innerWidth < BREAKPOINT_TABLET &&
      !document.body.classList.contains("fp-viewing-0")
    )
      setVideoProps((p) => ({ ...p, playing: false }))
  }, [setVideoProps])

  function closeAllMenus() {
    setChapterMenuOpen(false)
    setLanguageMenuOpen(false)
    closeMenu()
  }

  function handleMenuButtonClick() {
    if (languageMenuOpen) setLanguageMenuOpen(false)
    setChapterMenuOpen((co) => !co)
  }

  function handleLanguageButtonClick(e) {
    if (chapterMenuOpen) setChapterMenuOpen(false)
    setLanguageMenuOpen((lo) => !lo)
  }

  useEffect(() => {
    if (chapterMenuOpen || languageMenuOpen) openMenu()
    else closeMenu()
  }, [chapterMenuOpen, languageMenuOpen, closeMenu, openMenu])

  return (
    <>
      <header className="header">
        <ChapterMenu
          articles={articles}
          closeAllMenus={closeAllMenus}
          open={chapterMenuOpen}
          selected={selected}
        />
        <LanguageMenu
          onClick={handleLanguageButtonClick}
          closeAllMenus={closeAllMenus}
          open={languageMenuOpen}
          languages={languages}
          setLang={setLang}
          articles={articles}
          selected={selected}
        />
      </header>
      <Portal>
        <button
          className={`menu-button hamburger hamburger--collapse" +
            ${chapterMenuOpen ? " is-active" : ""}`}
          onClick={handleMenuButtonClick}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <button className="language-button" onClick={handleLanguageButtonClick}>
           <FontAwesomeIcon icon="globe-europe" />
          <span
            className="language-button-name"
            data-long={lang.name}
            data-short={lang.short}
          />
        </button>
      </Portal>
    </>
  )
}

export default Header
