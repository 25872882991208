import React, { useState, useEffect } from "react"
import smoothscroll from "smoothscroll-polyfill"
import DonationEncourager from "donation-encourager"

import {
  useAllContents,
  useContents,
  ContentsContext,
  useLangs,
  LangContext,
  withAuth,
  withWebpCheck,
} from "gatsby-source-dek-wp"

import { useLangSetter } from "./languages"
import useVideo from "../background-video"
import { useCustomSwipeHanlder } from "../../modules/custom-swipe-handler"
import useDonationEncourager from "./donation-encourager"

import FullpageWrapper from "../fullpage-wrapper"
import Header from "../header"
import SiteLogo from "../site-logo"
import Article from "../article"
import StartPage from "../start-page"
import "../icons"
import useJumpUpDown from "./jump-up-down"
import { useJumpToSelected } from "./jump-to-selected"
import { usePrevSelected } from "./prev-selected"

checkBrowserSupport()

function App({ metaData, data, pageContext, children }) {
  const { langId, slug } = pageContext
  const { backgroundVideos, siteLogos, metaInfos, categoryNames } = metaData
  const firstLoad = useFirstLoad()
  const allArticles = useAllContents(data, false, categoryNames)
  const [lang, allLanguages] = useLangs(langId, allArticles)
  const [articles, selected] = useContents(allArticles, lang.id, slug)
  const prevSelected = usePrevSelected(selected, articles)
  useJumpToSelected(slug, allArticles, firstLoad)
  const jumpUpDown = useJumpUpDown(articles, selected, prevSelected, lang)
  const setLangById = useLangSetter(selected)
  const [setVideoProps, bgVideo] = useVideo(
    !selected?.title,
    articles,
    backgroundVideos
  )
  const [allArticlesWrapper] = useCustomSwipeHanlder(articles) // , preloadId
  useDonationEncourager(lang.id)

  const { title, description } = metaInfos.find((m) => m.langId === lang.id)

  return (
    <LangContext.Provider value={lang}>
      <ContentsContext.Provider value={articles}>
        {bgVideo}
        {children /* status messages */}
        <FullpageWrapper
          {...{
            setVideoProps,
            articles,
            selected,
            pageContext,
            firstLoad,
            prevSelected,
          }}
        >
          <div className="section">
            <StartPage
              title={title}
              subtitle={description}
              metaData={metaData}
              jumpUpDown={jumpUpDown}
            />
          </div>
          <div className="section" ref={allArticlesWrapper}>
            <Header
              articles={articles}
              languages={allLanguages}
              selected={selected}
              setLang={setLangById}
              setVideoProps={setVideoProps}
            />
            {articles.map((article, i, arr) => (
              <div key={article.orderId} className="slide">
                <Article
                  data={article}
                  isSelected={selected?.orderId === article.orderId}
                  staticContext={
                    selected?.orderId === article.orderId ? pageContext : {}
                  }
                  setVideoProps={setVideoProps}
                  metaData={metaData}
                  jumpUpDown={jumpUpDown}
                  // preload={article.id === preloadId}
                  nextArticleSlug={
                    typeof arr[i + 1] === "object" ? arr[i + 1].slug : ""
                  }
                />
              </div>
            ))}
          </div>
        </FullpageWrapper>
        <SiteLogo logos={siteLogos} jumpUpDown={jumpUpDown} />
        <DonationEncourager />
      </ContentsContext.Provider>
    </LangContext.Provider>
  )
}

export default withWebpCheck(withAuth(App))

function useFirstLoad() {
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    setFirstLoad(false)
  }, [])
  return firstLoad
}

function checkBrowserSupport() {
  if (typeof window !== "undefined") {
    smoothscroll.polyfill() // polyfill for Edge . . .
    // no support alert for IE
    if (
      window.navigator.userAgent.match(/MSIE /) ||
      !!window.navigator.userAgent.match(/Trident.*rv:11\./)
    )
      alert("Your browser is not supported.")
  }
}
