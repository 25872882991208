import { library } from "@fortawesome/fontawesome-svg-core"
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons"
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { faPause } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faMinus } from "@fortawesome/free-solid-svg-icons"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { faLockOpen } from "@fortawesome/free-solid-svg-icons"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faGetPocket } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faRss } from "@fortawesome/free-solid-svg-icons"
import { faStreetView } from "@fortawesome/free-solid-svg-icons"
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons"
import { faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons"

library.add(
  faGlobeEurope,
  faAngleDown,
  faVolumeMute,
  faVolumeUp,
  faPlay,
  faPause,
  faPlus,
  faMinus,
  faLock,
  faLockOpen,
  faFacebookF,
  faTwitter,
  faGetPocket,
  faEnvelope,
  faRss,
  faStreetView,
  faExpandArrowsAlt,
  faCompressArrowsAlt
)

export default library
