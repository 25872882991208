import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./social-bar.scss"

const SocialBar = ({ url }) => (
  <div className="ph-social-bar">
    <a
      className="ph-share-bar-widget ph-share-bar-widget-facebook"
      href={`http://www.facebook.com/sharer.php?u=${url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
    </a>
    <a
      className="ph-share-bar-widget ph-share-bar-widget-twitter"
      href={`http://twitter.com/intent/tweet?text=${url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={["fab", "twitter"]} />
    </a>
    <a
      className="ph-share-bar-widget ph-share-bar-widget-get-pocket"
      href={`https://getpocket.com/save?url=${url}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={["fab", "get-pocket"]} />
    </a>
    <a
      className="ph-share-bar-widget ph-share-bar-widget-email"
      href={`mailto:?subject=Toller%20Artikel%20auf%20dekoder.org&amp;body=Dieser%20Artikel%20interessiert%20dich%20bestimmt%3A%0D%0A${url}`}
    >
      <FontAwesomeIcon icon="envelope" />
    </a>
  </div>
)

export default SocialBar
