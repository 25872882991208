import React, { useState } from "react"
import "./site-logo.scss"

function SiteLogo({ logos, jumpUpDown }) {
  const [logoUrl, setLogoUrl] = useState(logos[0])
  const logoOnMouseOver = () => setLogoUrl(logos[1])
  const logoOnMouseOut = () => setLogoUrl(logos[0])
  return (
    <button
      className="site-logo"
      onClick={jumpUpDown}
      onKeyDown={jumpUpDown}
      onMouseOver={logoOnMouseOver}
      onFocus={logoOnMouseOver}
      onMouseOut={logoOnMouseOut}
      onBlur={logoOnMouseOver}
    >
      <img src={logoUrl} alt="site logo" />
    </button>
  )
}

export default SiteLogo
