import React, { useMemo, useState, useContext } from "react"
import { Link } from "gatsby"
import "./toc.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons"
import { ContentsContext } from "gatsby-source-dek-wp"
import { useTransformer } from "../../modules/transformer"

const TocItem = ({ link, title, description }) => {
  const [active, setActive] = useState(false)
  const transformedDescription = useTransformer(description)[0].props.children

  return (
    <div className={`toc-item ${active ? "active" : ""}`}>
      <h4 className="toc-item-title">
        <button onClick={() => setActive((a) => !a)}>
          <FontAwesomeIcon icon={active ? faMinusSquare : faPlusSquare} />
          <span>{title}</span>
        </button>
      </h4>
      <p className="toc-item-description">
        <small>
          {transformedDescription}{" "}
          <Link to={link} className="toc-link">
            → {title}
          </Link>
        </small>
      </p>
    </div>
  )
}

const Toc = () => {
  const articles = useContext(ContentsContext)
  const menuCategories = useMemo(() => {
    return articles.reduce((acc, article) => {
      if (article.menuCategory && !acc.includes(article.menuCategory))
        acc = [...acc, article.menuCategory]
      return acc
    }, [])
  }, [articles])

  return (
    <div className="toc">
      {menuCategories.map((category) => {
        const subArticles = articles.filter((a) => a.menuCategory === category)
        return (
          <div key={category} className="toc-category-wrapper">
            <div className="toc-category-inner">
              <h2 className="toc-category-title">{category}</h2>
              <div className="toc-category-sub-items">
                {subArticles
                  .filter((a) => a.pageType !== "toc")
                  .map((a, i) => (
                    <TocItem
                      key={`${category}_${i}`}
                      link={`/${a.slug}`}
                      title={a.title}
                      description={a.description}
                    />
                  ))}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

// <p><small>{a.description}</small></p>

export default Toc
