import React, { useState, useContext, useMemo } from "react"
import { DiscussionEmbed } from "disqus-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./comment-section.scss"
import { LangContext } from "gatsby-source-dek-wp"

const TITLE = {
  de: "Diskussion",
  en: "Discussion",
  ru: "Дискуссия",
  uk: "Дискусія",
}

const CommentSection = ({ initiallyOpen, intUrl, id, intTitle }) => {
  const langId = useContext(LangContext).id
  const [open, setOpen] = useState(initiallyOpen)
  const toggle = () => setOpen((o) => !o)
  const discusConfig = useMemo(
    () => ({
      url: intUrl,
      identifier: "crimea_" + id,
      title: intTitle,
    }),
    [intUrl, id, intTitle]
  )
  return (
    <div className="disqus-container">
      <hr />
      <button className="disqus-title" onClick={toggle} onKeyDown={toggle}>
        <h5>
          <FontAwesomeIcon icon={open ? "minus" : "plus"} />
          {TITLE[langId]}
        </h5>
      </button>
      {open && <DiscussionEmbed shortname="dekoder" config={discusConfig} />}
    </div>
  )
}

export default CommentSection
