import { useCallback } from "react"
import { navigate } from "gatsby"

export default function useJumpUpDown(articles, selected, prevSelected, lang) {
  const jumpUpDown = useCallback(() => {
    // logo click: change between sections 1 & 2
    const targetSection =
      window.fullpage_api.getActiveSection().index === 0
        ? "articles"
        : "startPage"
    const firstArticleSlug = articles[0].slug
    const secTwoSlug = prevSelected ? prevSelected.slug : firstArticleSlug
    targetSection === "startPage"
      ? navigate(`/${lang.id}`)
      : navigate(`/${secTwoSlug}`)
  }, [articles, selected, prevSelected, lang.id])
  return jumpUpDown
}
