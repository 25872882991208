import React, { useState, useRef, useMemo, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import JSON5 from "json5"
import BarInfographic from "../../modules/barInfographic"
import "./infographic.scss"

function Infographic({ sticky, options, dataUrl, dataJson }) {
  const [infographic, setInfographic] = useState(undefined)
  const ref = useRef()
  const id = useMemo(() => uuidv4(), [])
  useEffect(() => {
    infographicEmbedder(ref.current, setInfographic, options, dataUrl, dataJson)
  }, [dataJson, dataUrl, options])
  ;[].push(infographic)
  /*function handleEnter(el) {
    if (!el.querySelector("span")) return;
    const newValue = el.querySelector("span").getAttribute("data-x");
    if (infographic) infographic.x = newValue;
  }*/
  return (
    <div
      className={`infographic-container${sticky ? " sticky" : ""}`}
      id={id}
      ref={ref}
    ></div>
  )
}

export default Infographic

function infographicEmbedder(
  element,
  callback,
  optionsRaw,
  igDataUrl,
  dataJsonRaw
) {
  if (typeof BarInfographic === "undefined") return
  let igOptions = {}
  try {
    igOptions = JSON5.parse(optionsRaw)
  } catch (e) {
    console.log(e)
  }

  if (igDataUrl) {
    fetch(igDataUrl)
      .then((r) => {
        try {
          return JSON5.parse(r.text())
        } catch (e) {
          console.log(e)
          return false
        }
      })
      .then((data) => {
        if (!data) return
        const ig = new BarInfographic(data, element.id, igOptions)
        if (typeof callback === "function") callback(ig)
      })
  } else if (dataJsonRaw) {
    try {
      // console.log(igDataJson);
      const data = JSON5.parse(dataJsonRaw)
      // console.log(data);
      if (!data) return
      const ig = new BarInfographic(data, element.id, igOptions)
      if (typeof callback === "function") callback(ig)
    } catch (e) {
      console.log(e)
    }
  }
}
