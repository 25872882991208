import React from "react"
import MapSearch from "./map-search"
import TagManager from "./tag-manager"
import mapboxgl from "mapbox-gl"
import JSON5 from "json5"
import debounce from "debounce"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import scrollIntoView from "scroll-into-view"
import "./mapbox-gl.css"
import "./map.scss"

const BREAKPOINT_MOBILE = 850

/* const lockedButtonHtml = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock-open" class="svg-inline--fa fa-lock-open fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z"></path></svg>'; */

const unlockedButtonHtml =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock-open" class="svg-inline--fa fa-lock-open fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z"></path></svg>'

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.isMap = true
    this.ref = React.createRef()
    this.containerRef = React.createRef()
    this.tagManager = new TagManager()
    // this.pointsManager = new PointsManager();
    this.state = {
      locked: true,
      fullscreen: false,
      points: [],
      filteredPoints: [],
      selectedPoint: {},
      selectedTags: [],
      tags: [],
      jumping: false,
    }
    this.debouncedHighlightLockButton = debounce(
      this.highlightLockButton.bind(this),
      500,
      true
    )
  }

  get stateClasses() {
    let result = this.props.sticky ? " sticky" : ""
    result += this.state.locked ? " locked" : " unlocked"
    result += this.state.fullscreen ? " fullscreen" : ""
    return result
  }

  get fullscreenControl() {
    return this.map._controls.find((c) => c._fullscreenButton) || {}
  }

  toggleFullscreen() {
    this.setState(
      { fullscreen: !this.state.fullscreen }
      // () => console.log(this.state.fullscreen)
    )
  }

  scrollToElement(element, topOffset = 0) {
    this.setState({ jumping: true })
    scrollIntoView(
      element,
      {
        align: { top: 0, topOffset: topOffset },
      },
      () => this.setState({ jumping: false })
    )
  }

  generatePoints(pList) {
    let pointsArray = []
    // console.log(pList);
    pList.forEach((p) => {
      const pEl = p.ref.current
      if (!pEl) return
      if (pEl.querySelector("button.map-show-more-button")) {
        pEl.querySelector("button.map-show-more-button").innerHTML =
          unlockedButtonHtml +
          pEl.querySelector("button.map-show-more-button").innerHTML
        pEl
          .querySelector("button.map-show-more-button")
          .addEventListener("click", () => {
            this.showMore()
          })
      }
      if (pEl.querySelector("span") === null) return
      const boundGeneratePoint = generatePoint.bind(this)
      pointsArray.push(boundGeneratePoint(pEl))

      function generatePoint(el) {
        let point = {}
        const optionsAttr = el
          .querySelector("span")
          .getAttribute("data-options")
        if (optionsAttr) {
          try {
            point.options = JSON5.parse(optionsAttr)
          } catch (e) {
            console.log(e)
            console.log(optionsAttr)
          }
        }
        const tagAttr = el.querySelector("span").getAttribute("data-tags")
        const tagNames = tagAttr ? tagAttr.split(",").map((t) => t.trim()) : []
        point.tags = tagNames.map((t) =>
          this.tagManager.getOrAddTag(t, this.onTagClick.bind(this))
        )
        point.text = el.innerHTML
        const titleEl = el.querySelector("h3")
        point.name = titleEl.textContent

        const tagsEl = document.createElement("div")
        tagsEl.classList.add("map-tags-div")
        point.tags.forEach((t) => {
          const tagEl = t.tagEl.cloneNode(true)
          tagEl.addEventListener("click", () => t.clickCallback(t))
          tagsEl.appendChild(tagEl)
        })
        point.tagsEl = tagsEl

        titleEl.parentNode.insertBefore(tagsEl, titleEl.nextSibling)

        point.element = el
        point.color = point.tags.length ? point.tags[0].color : "#129fc9"
        // el.classList.contains('extra') ? '#129fc9' : 'rgba(20, 170, 170, 1)';
        return point
      }
    })
    this.setState({ points: pointsArray, filteredPoints: pointsArray })

    pointsArray.forEach((point) => this.addMapMarker(point))
  }

  onTagClick(tag) {
    this.setState({ locked: false })
    this.resetMapZoom()
    this.onMapFilterChange([tag])
  }

  showMore() {
    if (this.state.locked) this.toggleMapLock()
    this.resetMapZoom()
  }

  resetMapZoom() {
    this.map.flyTo(this.state.points[0].options)
  }

  addMapMarker(point) {
    if (!point.options || !this.map) return
    if (point.options.noMarker) return

    /*const popup = new mapboxgl.Popup()
        .setLngLat(point.options.center)
        .setHTML(point.text + '<div className="map-tags-div">' + point.tagsEl.innerHTML + '</div>');
      */
    const marker = new mapboxgl.Marker({ color: point.color })
      .setLngLat(point.options.center)
      // .setPopup(popup)
      .addTo(this.map)
    point.marker = marker

    const markerEl = marker.getElement()
    markerEl.addEventListener("click", (e) => {
      if (this.state.fullscreen) return
      this.setSelectedPoint(point)
      this.scrollToPoint(point)
      // this.map.flyTo(point.options);
    })

    markerEl.addEventListener("wheel", this.handleEvents.bind(this), true)
    markerEl.addEventListener(
      "touchmove",
      this.markMapTouchMove.bind(this),
      true
    )
  }

  getPointFromEl(el) {
    const name = el.querySelector("h3").textContent
    return this.state.points.find((p) => p.name === name)
  }

  onMapSearchChange(option) {
    const point = option
      ? this.state.points.find((p) => p.name === option.value)
      : this.state.points[0]
    if (point) {
      this.map.flyTo(point.options)
      this.scrollToPoint(point)
    }
  }

  onMapFilterChange(tagArray = []) {
    this.setState({ selectedTags: tagArray })
    const selectedTagNames = (tagArray || []).map((t) => t.value)
    const filteredPoints = this.state.points.filter((p) => {
      const isSelected = pointHasAllTags(p, selectedTagNames)
      p.selected = isSelected
      if (p.marker) {
        p.marker.getElement().classList.add(isSelected ? "active" : "inactive")
        p.marker
          .getElement()
          .classList.remove(isSelected ? "inactive" : "active")
      }
      return isSelected
    })
    this.setState({ filteredPoints: filteredPoints })

    function pointHasAllTags(point, selectedTagNames) {
      let result = selectedTagNames.every((sT) =>
        point.tags.map((t) => t.name).includes(sT)
      )
      return result
    }
  }

  scrollToPoint(point) {
    this.setSelectedPoint(point)
    const topOffset =
      window.innerWidth < BREAKPOINT_MOBILE ? this.ref.current.offsetHeight : 0
    this.scrollToElement(point.element, topOffset)
  }

  setSelectedPoint(point) {
    this.setState({ selectedPoint: point })
    point.element.children[0].classList.remove("extra")
  }

  handleEnter(ref) {
    const el = ref.current
    if (!el.querySelector("span")) return
    if (this.state.jumping) return
    const titleEl = el.querySelector("h3")
    let options = {}
    if (titleEl) {
      const name = titleEl.textContent
      const point = this.state.points.find((p) => p.name === name)
      options = point ? point.options : false
    }
    if (options && this.map && !el.classList.contains("extra")) {
      if (this.ref.current.getBoundingClientRect().width < 600)
        options.zoom = options.zoom * 0.9
      this.map.flyTo(options)
      this.setSelectedPoint(this.getPointFromEl(el))
    }
  }

  toggleMapLock() {
    // this.props.article.setState({allowHorizontalSwipe: !this.state.locked});
    this.setState({ locked: !this.state.locked })
  }

  componentDidMount() {
    this.map = mapEmbedder(this.ref.current)
    if (!this.map) return
    try {
      // unlock in fullscreen mode:
      if (this.fullscreenControl._fullscreenButton)
        this.fullscreenControl._fullscreenButton.addEventListener(
          "click",
          () => {
            this.setState({
              locked: this.fullscreenControl._fullscreen ? true : false,
              fullscreen: this.fullscreenControl._fullscreen ? false : true,
            })
          }
        )
      // bugfix: sometimes initial height is not set correctly
      setTimeout(() => {
        this.map.resize()
      }, 1000)
    } catch (e) {
      console.log(e)
    }

    this.ref.current
      .querySelector("canvas")
      .addEventListener("wheel", this.handleEvents.bind(this), true)
    this.ref.current
      .querySelector("canvas")
      .addEventListener("touchmove", this.handleEvents.bind(this), true)
    this.ref.current
      .querySelector("canvas")
      .addEventListener("touchmove", this.markMapTouchMove.bind(this), true)
    this.ref.current
      .querySelector("canvas")
      .addEventListener("touchstart", this.handleEvents.bind(this), true)
  }
  highlightLockButton() {
    // console.log('fire');
    this.ref.current.classList.add("map-lock-button-highlight")
    setTimeout(() => {
      this.ref.current.classList.remove("map-lock-button-highlight")
    }, 1000)
  }
  markMapTouchMove(e) {
    e.isMapTouchMove = true
  }
  handleEvents(e) {
    if (this.state.locked && e.cancelable) {
      e.preventDefault()
      e.stopPropagation()
      this.debouncedHighlightLockButton()
      if (e.type === "wheel") {
        const divEl = this.props.article?.divEl
        if (!divEl) return
        divEl.current.scrollTop = divEl.current.scrollTop + e.deltaY
      }
    }
  }
  render() {
    return (
      <div
        ref={this.containerRef}
        className={"map-container" + this.stateClasses}
      >
        <div ref={this.ref}>
          <button
            className="map-lock-button"
            onClick={() => this.toggleMapLock()}
          >
            <FontAwesomeIcon icon={this.state.locked ? "lock" : "lock-open"} />
          </button>
          {this.state.locked ? (
            ""
          ) : (
            <MapSearch
              points={this.state.filteredPoints}
              tagManager={this.tagManager}
              selectedPoint={this.state.selectedPoint}
              selectedTags={this.state.selectedTags}
              onMapSearchChange={this.onMapSearchChange.bind(this)}
              onMapFilterChange={this.onMapFilterChange.bind(this)}
            />
          )}
        </div>
      </div>
    )
  } // <i className={this.state.locked ? 'fas fa-lock' : 'fas fa-lock-open'}></i>
  /* 

              <button className="map-fullscreen-button" onClick={() => this.props.article.toggleFullscreen()}>
                <FontAwesomeIcon icon={this.props.fullscreen ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
              </button>
            */
}

export default Map

function mapEmbedder(element) {
  // if (!document.getElementById(elementId)) return;
  if (typeof mapboxgl === "undefined") return
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_KEY
  const map = new mapboxgl.Map({
    container: element,
    style:
      // 'mapbox://styles/dekoder-org/ck3vdwdyz0jln1co8u7lpuxlb', // sattelite
      // "mapbox://styles/dekoder-org/ck3vecs0a11fd1cm7e60fcup9", // cali
      // "mapbox://styles/dekoder-org/ck3vefeoh51wh1co3qh2yad6k", // bubble
      "mapbox://styles/dekoder-org/ck3ve9tru30f01cltmyyy0hqb", // mineral
    // 'mapbox://styles/dekoder-org/cjk9m9bpndr2p2rlsy3193va2', // north star
    // 'mapbox://styles/dekoder-org/cjka6c8i834yj2row0x14o5nc', // outdoors
    center: [34.4, 45.2],
    zoom: 7.4,
  })
  // map.scrollZoom.disable();
  // if (isMobile()) map.dragPan.disable();
  map.addControl(
    new mapboxgl.NavigationControl({ showZoom: true }),
    "bottom-left"
  )
  // map.addControl(new mapboxgl.FullscreenControl());
  return map
}
